const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['buyer-account'] = require('../middleware/buyer-account.js')
middleware['buyer-account'] = middleware['buyer-account'].default || middleware['buyer-account']

middleware['cart-checkout'] = require('../middleware/cart-checkout.js')
middleware['cart-checkout'] = middleware['cart-checkout'].default || middleware['cart-checkout']

middleware['default'] = require('../middleware/default.js')
middleware['default'] = middleware['default'].default || middleware['default']

middleware['has-group'] = require('../middleware/has-group.js')
middleware['has-group'] = middleware['has-group'].default || middleware['has-group']

middleware['redirects'] = require('../middleware/redirects.js')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['role'] = require('../middleware/role.js')
middleware['role'] = middleware['role'].default || middleware['role']

middleware['trailingSlashRedirect'] = require('../middleware/trailingSlashRedirect.js')
middleware['trailingSlashRedirect'] = middleware['trailingSlashRedirect'].default || middleware['trailingSlashRedirect']

middleware['typeValidation'] = require('../middleware/typeValidation.js')
middleware['typeValidation'] = middleware['typeValidation'].default || middleware['typeValidation']

middleware['validation.metal'] = require('../middleware/validation.metal.js')
middleware['validation.metal'] = middleware['validation.metal'].default || middleware['validation.metal']

middleware['validation.subcategory'] = require('../middleware/validation.subcategory.js')
middleware['validation.subcategory'] = middleware['validation.subcategory'].default || middleware['validation.subcategory']

middleware['validation.type'] = require('../middleware/validation.type.js')
middleware['validation.type'] = middleware['validation.type'].default || middleware['validation.type']

middleware['wishlist'] = require('../middleware/wishlist.js')
middleware['wishlist'] = middleware['wishlist'].default || middleware['wishlist']

export default middleware
