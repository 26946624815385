export const currencys = [
  {
    label: 'amd',
    code: 'amd',
    simbol: '֏'
  },
  {
    label: 'RUB',
    code: 'rub',
    simbol: '₽'
  },
  {
    label: 'USD',
    code: 'usd',
    simbol: '$'
  },
  {
    label: 'EUR',
    code: 'eur',
    simbol: '€'
  }
]
