export default {
  state: () => {
    return {
      goldPriceInfo: {},
      goldFinenessInfo: {},
      goldBookingInfo: {
        currency: 'USD',
        expired_at: '',
        metal: '',
        per_gram_price: 0,
        taking_time: 0,
        total_amount: 0,
        type: '',
        weight: 0
      }
    }
  },
  mutations: {
    setGoldPriceInfo (state, payload) {
      state.goldPriceInfo = payload
    },
    setGoldFinenessInfo (state, payload) {
      state.goldFinenessInfo = payload
    },
    setGoldBookingInfo (state, payload) {
      state.goldBookingInfo = payload
    }
  },
  actions: {
    async chackGoldPrice ({ commit }, body) {
      const data = await this.$api.buyerAccount.tools.chackGoldPrice(body)
      const mutationData = Object.keys(data.response).map(key => ({
        ...data.response[key],
        id: key
      }))
      commit('setGoldPriceInfo', mutationData)
    },
    async chackCalculateFineness ({ commit }, body) {
      const data = await this.$api.buyerAccount.tools.chackCalculateFineness(
        body
      )
      const mutationData = Object.keys(data.fineness).map(key => ({
        ...data.fineness[key],
        id: key
      }))
      commit('setGoldFinenessInfo', mutationData)
    },

    async chackCalculateBooking ({ commit }, body) {
      const data = await this.$api.buyerAccount.tools.chackCalculateBooking(
        body
      )
      commit('setGoldBookingInfo', data)
    },

    async checkUserStatusBooking () {
      const { valid } =
        await this.$api.buyerAccount.tools.checkUserStatusBooking()
      return valid
    },
    acceptBooking (_, body) {
      return this.$api.buyerAccount.tools.acceptBooking(body)
    }
  },
  namespaced: true
}
