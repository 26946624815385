export default {
  state: () => {
    return {
      brandListInfo: {},
      brand: {}
    }
  },
  mutations: {
    setBrandListInfo (state, payload) {
      state.brandListInfo = payload
    },

    setBrand (state, payload) {
      state.brand = payload
    }
  },
  actions: {
    async list ({ commit, state }, query) {
      const { loadMore } = query
      const data = await this.$api.brand.list(query)
      const mutationData = loadMore
        ? {
            ...data,
            catalog: [...state.brandListInfo.catalog, ...data.catalog]
          }
        : data
      commit('setBrandListInfo', mutationData)
    },

    async get ({ commit, state }, query) {
      const { loadMore } = query
      const data = await this.$api.brand.get(query)
      const mutationData = loadMore
        ? { ...data, brands: [...state.brand.brands, ...data.brands] }
        : data
      commit('setBrand', mutationData)
    }
  },
  namespaced: true
}
