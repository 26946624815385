export default {
  state: () => {
    return {
      cartListInfo: {}
    }
  },
  mutations: {
    setCartListInfo (state, payload) {
      state.cartListInfo = payload
    }
  },
  actions: {
    async get ({ commit }, sendData) {
      const data = await this.$api.cart.get(sendData)
      commit('setCartListInfo', data)
    },

    add (_, sendData) {
      return this.$api.cart.add(sendData)
    },

    async remove (_, id) {
      await this.$api.cart.remove(id)
    },

    async changeTotal (_, data) {
      await this.$api.cart.changeTotal(data)
    },

    checkout (_, sendData) {
      return this.$api.cart.checkout(sendData)
    }
  },
  getters: {
    isOrderBy (state) {
      const { catalog } = state.cartListInfo
      if (catalog?.length) {
        return !!catalog.filter(cart => cart.available === 'by_order')[0]
      }
      return false
    }
  },
  namespaced: true
}
