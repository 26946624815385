import dashboard from './dashboard'
import offers from './offers'
import deliveryAddress from './delivery-address'
import orderHistory from './order-history'
import tools from './tools'

export default {
  modules: { dashboard, offers, deliveryAddress, orderHistory, tools },
  namespaced: true
}
