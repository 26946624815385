import { getQuery } from '../core/utils/queryAPI'

export default $axios => ({
  async list ({ query, id }) {
    const { data } = await $axios.get(
      `/catalog/seller/${id}?${getQuery(query)}`
    )
    return data
  },

  async get ({ query }) {
    const { data } = await $axios.get(`/brands?${getQuery(query)}`)
    return data
  }
})
