export default async function ({ redirect }) {
  try {
    const access = await this.$axios.$get('/preorder/access')
    if (!access) {
      // redirect('/')
      return 0
    }
  } catch (error) {
    // redirect('/')
  }
}
