export default {
  state: () => {
    return {
      orderHistoryInfo: {},
      order: null
    }
  },
  mutations: {
    setOrderHistoryInfo (state, payload) {
      state.orderHistoryInfo = payload
    },
    setOrder (state, payload) {
      state.order = payload
    }
  },
  actions: {
    async list ({ commit }) {
      const data = await this.$api.buyerAccount.orderHistory.list()
      commit('setOrderHistoryInfo', data)
    },
    async get ({ commit }, id) {
      const data = await this.$api.buyerAccount.orderHistory.get(id)
      commit('setOrder', data)
    }
  },
  namespaced: true
}
