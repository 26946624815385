import { shoppingCenter } from '~/static/mock/lists'

export default {
  state: () => {
    return {
      shoppingCenters: shoppingCenter,
      shoppingCenter: [],
      regions: [],
      cities: []
    }
  },
  mutations: {
    setShoppingCenters (state, payload) {
      state.shoppingCenters = payload
    },
    setShoppingCenter (state, payload) {
      state.shoppingCenter = payload
    },
    setRegions (state, payload) {
      state.regions = payload
    },
    setCities (state, payload) {
      state.cities = payload
    }
  },
  actions: {
    async list ({ commit }, query) {
      const { list } = await this.$api.shoppingCenter.list(query)
      commit('setShoppingCenters', list)
    },
    async get ({ commit }, id) {
      const { list } = await this.$api.shoppingCenter.get(id)
      commit('setShoppingCenter', list)
    }
  },
  namespaced: true
}
