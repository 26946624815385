import Vue from 'vue'
import AppPrice from '~/components/ui/app-price.vue'
import AppPriceNew from '~/components/ui/app-price-new.vue'
import AppPhoneLink from '~/components/ui/app-phone-link.vue'
import AppTransitionExpat from '~/components/ui/app-transition-expat.vue'
import IconArrowSmall from '~/components/icons/icon-arrow-small.vue'
import IconArrowPosition from '~/components/icons/icon-arrow-position.vue'
import IconArrowExpat from '~/components/icons/icon-arrow-expat.vue'

const components = {
  AppPrice,
  AppPriceNew,
  AppTransitionExpat,
  AppPhoneLink,
  IconArrowSmall,
  IconArrowExpat,
  IconArrowPosition
}

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})
