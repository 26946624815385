export default $axios => ({
  async list () {
    const { data } = await $axios.get('/order/buyer/all?limit=1000&offset=0')
    return data
  },
  async get (id) {
    const { data } = await $axios.get(`/order/${id}`)
    return data
  }
})
