import { getQuery } from '../core/utils/queryAPI'

export default $axios => ({

  async list (query, params = []) {
    const { data } = await $axios.get(`/catalog/top?${getQuery(query)}`)
    return data
  }

})
