export default {
  state: () => {
    return {}
  },
  mutations: {},
  actions: {
    async get (_, id) {
      const data = await this.$api.user.get(id)
      return data
    },
    async put (_, sendData) {
      await this.$api.user.put(sendData)
    },

    async aboutStore (_, id) {
      const data = await this.$api.user.aboutStore(id)
      return data
    }
  },
  namespaced: true
}
