import { types } from '~/core/utils/header-navigation'

export default function ({ redirect, route, localePath }) {
  const { params } = route
  const isValidType = types.includes(params.type)
  // if (
  //   query.userType === 'manufacturer' &&
  //   query?.conditional === 'mounting' &&
  //   params?.type === 'chain'
  // ) {
  //   redirect(localePath('/'))
  // }
  if (!isValidType) {
    redirect(localePath('/'))
  }
}
