<template>  
  <svg
    width="10"
    height="8"
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.226216 3.64645C0.0309543 3.84171 0.0309544 4.15829 0.226216 4.35355L3.4082 7.53553C3.60346 7.7308 3.92004 7.7308 4.1153 7.53553C4.31057 7.34027 4.31057 7.02369 4.1153 6.82843L1.28688 4L4.1153 1.17157C4.31057 0.976311 4.31057 0.659729 4.1153 0.464467C3.92004 0.269204 3.60346 0.269204 3.4082 0.464467L0.226216 3.64645ZM9.42187 3.5L0.57977 3.5L0.57977 4.5L9.42188 4.5L9.42187 3.5Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'black'
    }
  }
}
</script>
