import { combineAPI } from '~/api'
import { ErrorCode } from '~/core/utils/ErrorCode'

function onRequestBody ({ $cookies, config, store }) {
  if (
    !config.url.startsWith('/rate/cba') &&
    !config.url.startsWith('/rate/local') &&
    !config.url.startsWith('/search') &&
    !config.url.startsWith('/rate/global') &&
    !config.url.startsWith('/rate/local_history/')
  ) {
    store.commit('ui/SET_REQUEST_STARTED')
  }

  const accessToken = $cookies.get('accessToken')
  if (!config.url.startsWith('http')) {
    config.baseURL = process.env.VUE_APP_API_URL
  }

  if (accessToken) {
    config.headers['x-access-token'] = accessToken
  }
}

function onErrorBody ({ $cookies, error, store, redirect }) {
  store.commit('ui/SET_REQUEST_FINISHED')
  if (error.response?.status === 401) {
    store.commit('auth/logout')
    redirect('/')
  } else {
    const lang = $cookies.get('lang') || ''
    console.error(ErrorCode[lang][error.response?.data?.code] || 'Something went wrong')
    //   store.commit('ui/setAlert', {
    //     message:
    //       ErrorCode[lang][error.response?.data?.code] || 'Something went wrong',
    //     type: 'error'
    //   })
  }
}

export default function ({ $axios, redirect, store, $cookies }, inject) {
  $axios.onRequest((config) => {
    onRequestBody({ config, $cookies, store })
  })

  $axios.onError((error) => {
    onErrorBody({ error, store, redirect, $cookies })
  })

  $axios.onResponse((response) => {
    store.commit('ui/SET_REQUEST_FINISHED')
    return response
  })

  const api = combineAPI($axios)

  inject('api', api)
}
