import Vue from 'vue'
import VueMask from 'v-mask'

Vue.directive('src', (el, binding) => {
  el.src = binding.value || ''
  // `https://storage.yandexcloud.net/image-goldcenter/${
  // }`
})
Vue.directive('bg-img', (el, binding) => {
  el.style.backgroundImage = `url(${binding.value || ''})`
})

Vue.directive('text-line-clamp', (el, binding) => {
  if (process.browser) {
    el.style.webkitLineClamp = binding.value
    el.style.overflow = 'hidden'
    el.style.textOverflow = 'ellipsis'
    el.style.display = '-webkit-box'
    el.style.webkitBoxOrient = 'vertical'
  }
})

Vue.directive('numericOnly', {
  bind (el) {
    el.addEventListener('input', () => {
      const regex = /^[0-9+.]*$/
      if (!regex.test(el.value)) {
        el.value = el.value.slice(0, -1)
      }
    })
  }
})

Vue.use(VueMask)
