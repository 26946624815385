import { mapState } from 'vuex'
import Tooltip from 'element-ui/lib/tooltip'

export default {
  components: { Tooltip },
  computed: {
    ...mapState({
      currentUser: state => state.auth.currentUser,
      previousRoutePath: state => state.previousRoute.previousRoutePath,
      currentRoute: state =>
        state.previousRoute.routes[state.previousRoute.currentRouteIndex]
    }),

    isBuyer () {
      return this.currentUser?.role_id === 4 || null
    },
    requestFinished () {
      return (
        !!this.$store.state.ui.requestsRunning &&
        this.$store.state.ui.loaderEnabled
      )
    },
    currentUserFullName () {
      if (!this.currentUser) {
        return ''
      }
      const [name] = this.currentUser.lastname
      return `${this.currentUser.firstname} ${name}.`
    }
  },
  methods: {
    scrollToTop () {
      if (process.browser) {
        window.scrollTo({ top: 0 })
      }
    },
    calcOptionsHeigth (arr, checkbox) {
      const height = checkbox ? 40 : 33
      const count = arr?.length || arr || 1
      if (count <= 7) {
        return `min-height: ${count * height + 17}px !important;`
      } else {
        return 'min-height: 250px !important;'
      }
    }
  }
}
