<template>
  <span class="price">
    {{ formatPrice(localPrice || renderPrice) || '' }}
  </span>
</template>

<script>
import { mapState } from 'vuex'
import { currencys } from '~/core/utils/currencys'
import { formatPrice } from '~/core/utils'

export default {
  name: 'AppPrice',
  props: {
    currencyProps: {
      required: false,
      type: String,
      default: () => ''
    },
    product: {
      type: Object
    },
    price: {
      type: [Number, String, undefined]
    }
  },
  computed: {
    ...mapState({
      currenciesInfo: state => state.buyerAccount.dashboard.currencies || {},
      handleChangeCurency: state => state.ui.currency || ''
    }),
    simbol () {
      const currency = this.handleChangeCurency && (this.currencyProps || this.$cookies.get('currency') || '')
      const felem = currencys.find(e => e.code === currency)
      return felem?.simbol || ''
    },
    localPrice () {
      return this.price ? `${this.price}֏` : undefined
    },
    renderPrice () {
      const currencies = { '֏': 'price', $: 'price_usd', '₽': 'price_rub', '€': 'price_eur' }
      const localPrice = this.product[currencies[this.simbol]]
      const price = this.simbol === '֏' ? localPrice.toFixed(0) : localPrice
      return this.product && this.handleChangeCurency && (price + this.simbol)
    }
  },
  methods: {
    formatPrice
  }
}
</script>

<style scoped>
.price{
  word-spacing: -3px;
}
</style>
