export function setMode ({ commit }, mode) {
  commit('setMode', mode)
}
export default {
  state: () => {
    return {
      sitemode: ''
    }
  },
  mutations: {
    setMode (state, payload) {
      state.sitemode = payload
    }
  },
  actions: {
    updateSiteMode ({ commit }, value) {
      this.$cookies.set('sitemode', value)
      commit('siteMode', value)
    }
  },
  namespaced: true
}
