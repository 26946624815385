export default async function ({
  store,
  redirect,
  localePath,
  $cookies,
  route
}) {
  try {
    const [name] = route.name.split('___')
    if (name !== 'buyer-account' && name !== 'buyer-account-tools') {
      await store.dispatch('auth/getCurrentUser')
      const currentUser = store.state.auth.currentUser
      // const isBuyer = currentUser.role_id === 4

      if (
        currentUser &&
        name !== 'buyer-account' &&
        name !== 'buyer-account-tools'
      ) {
        const lang = await $cookies.get('lang')
        redirect(
          `https://admin.goldcenter.am?token=${$cookies.get(
            'accessToken'
          )}&lang=${lang}`
        )
      }
    }
  } catch { }
}
