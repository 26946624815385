export default $axios => ({
  async chackGoldPrice (body) {
    const { data } = await $axios.post('/tools/check_gold_price', body)
    return data
  },
  async chackCalculateFineness (body) {
    const { data } = await $axios.post('/tools/calculate_fineness', body)
    return data
  },
  async chackCalculateBooking (body) {
    const { data } = await $axios.post('/booking/request', body)
    return data
  },
  async checkUserStatusBooking () {
    const { data } = await $axios.get('/booking/user/validate')
    return data
  },
  async acceptBooking (body) {
    const { data } = await $axios.post('/booking/confirm', body)
    return data
  }
})
