import { getQuery } from '../core/utils/queryAPI'

export default $axios => ({
  async list (body) {
    const { query } = body
    const { data } = await $axios.get(`/products/catalog?${getQuery(query)}`)
    return data
  },

  async get (id) {
    const { data } = await $axios.get(`/catalog/${id}`)
    return data
  },

  async getSeller (id) {
    const { data } = await $axios.get(`/catalog/seller/${id}`)
    return data
  }
})
