const TIME_OUT_DEF = 400

function renderSellerPhone (phone) {
  const [n1, n2, n3, n4, n5, n6, n7, n8, n9, n10, n11] = phone
  return `+${n1 + n2 + n3} (${n4 + n5}) ${n6 + n7 + n8}-${n9 + n10 + n11}`
}

function debounce (func, timeout = TIME_OUT_DEF) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => { func.apply(this, args) }, timeout)
  }
}

function formatPrice (strNumber) {
  const currency = strNumber[strNumber.length - 1]
  const parts = strNumber.toString().split('.')
  const integerPart = parts[0]
  // const decimalPart = parts[1]?.replace(/\D/g, '') || '00'

  const formattedIntegerPart = integerPart.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ', ')

  // let result = formattedIntegerPart
  // const decimal = decimalPart.length === 1 ? decimalPart + '0' : decimalPart
  // if (currency !== '֏') {
  //   result += `.${decimal}`
  // }
  return formattedIntegerPart + currency
}

export { renderSellerPhone, debounce, formatPrice, TIME_OUT_DEF }
