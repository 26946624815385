const getQuery = (query) => {
  let result = ''
  if (!query) {
    return ''
  }
  Object.keys(query).forEach((elem) => {
    if (elem && query[elem]) {
      result += `${elem}=${query[elem]}&`
    }
  })

  if (result[result.length - 1] === '&') {
    result = result.substring(0, result.length - 1)
  }
  return result
}
export { getQuery }
