export default {
  state: () => {
    return {
      currencies: {}
    }
  },
  mutations: {
    setCurrencies (state, payload) {
      state.currencies = payload
    }
  },
  actions: {
    global () {
      return this.$api.buyerAccount.dashboard.global()
    },
    local () {
      return this.$api.buyerAccount.dashboard.local()
    },
    history (_, body) {
      return this.$api.buyerAccount.dashboard.history(body)
    },
    async cba ({ commit }) {
      const data = await this.$api.buyerAccount.dashboard.cba()
      commit('setCurrencies', data)
      return data
    }
  },
  namespaced: true
}
