import { metals, types } from '~/core/utils/header-navigation'

export default function ({ redirect, route, localePath }) {
  const { params, query } = route
  const isValidType = types.includes(params.type)
  const isValidMetal = metals.includes(params.metal)
  if (
    query.userType === 'manufacturer' &&
    query?.conditional === 'mounting' &&
    params?.metal === 'gold' &&
    params?.type === 'chain'
  ) {
    redirect(localePath('/'))
  }
  if (!isValidType || !isValidMetal) {
    redirect(localePath('/'))
  }
}
