function socialMeta (options) {
  // All meta tags
  const metaTags = [
    // Global
    { name: 'author', content: options.url },
    { name: 'publisher', content: options.url },
    { name: 'apple-mobile-web-app-title', content: options.title },

    // Facebook & LinkedIn
    { property: 'og:title', content: options.title },
    { property: 'og:description', content: options.description },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: options.url },
    { property: 'og:image', content: options.img },
    { property: 'og:image:alt', content: options.title + ' page cover' },
    { property: 'og:locale', content: options.locale },
    { property: 'og:site_name', content: options.site_name },

    // Twitter
    { name: 'twitter:card', content: options.twitter_card },
    { name: 'twitter:site', content: options.twitter },
    { name: 'twitter:creator', content: options.twitter },
    { name: 'twitter:title', content: options.title },
    { name: 'twitter:description', content: options.description },
    { name: 'twitter:image', content: options.img }
  ]

  // Add meta tags to head
  const logicTag = (tag) => {
    const structure = name => ({
      hid: tag[name],
      name: tag[name],
      content: tag.content
    })
    const types = ['name', 'property']
    if (tag.content !== undefined && tag.content !== null) {
      if (Object.prototype.hasOwnProperty.call(tag, types[0])) {
        return structure(types[0])
      } else if (Object.prototype.hasOwnProperty.call(tag, types[1])) {
        return structure(types[1])
      }
    }
  }
  return metaTags.map(logicTag)
}

export default ({ app }, inject) => {
  inject('socialMeta', socialMeta)
}
