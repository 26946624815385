export const headerNavigation = {
  gold: [{
    title: 'menu.gold_jewelry',
    to: '/gold',
    metal: 'gold',
    menu: [
      {
        title: 'menu.women',
        to: 'women',
        key: 'women-gold',
        subMenu: [
          {
            title: 'menu.ring',
            to: '/gold/ring/women',
            submenu: [
              {
                title: 'menu.ring',
                to: '/gold/ring/women'
              },
              {
                title: 'menu.wed_ring',
                to: '/gold/wed_ring/women'
              },
              {
                title: 'menu.eng_ring',
                to: '/gold/eng_ring/women'
              }
            ]
          },
          {
            title: 'menu.earring',
            to: '/gold/earring/women'
          },
          {
            title: 'menu.bracelet',
            to: '/gold/bracelet/women',
            submenu: [
              {
                title: 'menu.hard_bracelet',
                to: '/gold/hard_bracelet/women'
              },
              {
                title: 'menu.chain_bracelet',
                to: '/gold/chain_bracelet/women'
              }
            ]
          },
          {
            title: 'menu.necklace',
            to: '/gold/necklaces/women',
            submenu: [
              {
                title: 'menu.necklace',
                to: '/gold/necklace/women'
              },
              {
                title: 'menu.chain',
                to: '/gold/chain/women'
              }
            ]
          },
          {
            title: 'menu.set',
            to: '/gold/set/women'
          },
          {
            title: 'menu.accessories',
            to: '/gold/accessories/women'
          },
          {
            title: 'menu.pendant',
            to: '/gold/pendants/women',
            submenu: [
              {
                title: 'menu.pendant',
                to: '/gold/pendant/women'
              },
              {
                title: 'menu.cross',
                to: '/gold/cross/women'
              }
            ]
          }
        ]
      },
      {
        title: 'menu.men',
        to: 'men',
        key: 'men-gold',
        subMenu: [
          {
            title: 'menu.ring',
            to: '/gold/ring/men',
            submenu: [
              {
                title: 'menu.ring',
                to: '/gold/ring/men'
              },
              {
                title: 'menu.wed_ring',
                to: '/gold/wed_ring/men'
              }
            ]
          },
          {
            title: 'menu.bracelet',
            to: '/gold/bracelet/men',
            submenu: [
              {
                title: 'menu.hard_bracelet',
                to: '/gold/hard_bracelet/men'
              },
              {
                title: 'menu.chain_bracelet',
                to: '/gold/chain_bracelet/men'
              }
            ]
          },
          {
            title: 'menu.necklace',
            to: '/gold/necklace/men',
            submenu: [
              {
                title: 'menu.necklace',
                to: '/gold/necklace/men'
              },
              {
                title: 'menu.chain',
                to: '/gold/chain/men'
              }
            ]
          },
          {
            title: 'menu.set',
            to: '/gold/set/men'
          },
          {
            title: 'menu.accessories',
            to: '/gold/accessories/men'
          },
          {
            title: 'menu.pendant',
            to: '/gold/pendant/men',
            submenu: [
              {
                title: 'menu.pendant',
                to: '/gold/pendant/men'
              },
              {
                title: 'menu.cross',
                to: '/gold/cross/men'
              }
            ]
          }
        ]
      },
      {
        title: 'menu.kids',
        to: 'kids',
        key: 'children-gold',
        subMenu: [
          {
            title: 'menu.ring',
            to: '/gold/ring/kids'
          },
          {
            title: 'menu.earring',
            to: '/gold/earring/kids'
          },
          {
            title: 'menu.bracelet',
            to: '/gold/bracelet/kids',
            submenu: [
              {
                title: 'menu.hard_bracelet',
                to: '/gold/hard_bracelet/kids'
              },
              {
                title: 'menu.chain_bracelet',
                to: '/gold/chain_bracelet/kids'
              }
            ]
          },
          {
            title: 'menu.necklace',
            to: '/gold/necklace/kids',
            submenu: [
              {
                title: 'menu.necklace',
                to: '/gold/necklace/kids'
              },
              {
                title: 'menu.chain',
                to: '/gold/chain/kids'
              }
            ]
          },
          {
            title: 'menu.set',
            to: '/gold/set/kids'
          },
          {
            title: 'menu.accessories',
            to: '/gold/accessories/kids'
          },
          {
            title: 'menu.pendant',
            to: '/gold/pendant/kids',
            submenu: [
              {
                title: 'menu.pendant',
                to: '/gold/pendant/kids'
              },
              {
                title: 'menu.cross',
                to: '/gold/cross/kids'
              }
            ]
          }
        ]
      },
      {
        title: 'menu.accessories',
        to: 'accessories',
        key: 'accessories-gold',
        subMenu: [
          {
            title: 'menu.watches',
            to: '/gold/watches/accessories'
          },
          {
            title: 'menu.brooch',
            to: '/gold/brooch/accessories'
          },
          {
            title: 'menu.other',
            to: '/gold/other/accessories'
          }
        ]
      },
      {
        title: 'menu.souvenir',
        to: 'souvenir',
        key: 'souvenir-gold',
        subMenu: [
          {
            title: 'menu.gold_bars',
            to: '/gold/bars/gifts'
          },
          {
            title: 'menu.book',
            to: '/gold/book/gifts'
          },
          {
            title: 'menu.other',
            to: '/gold/other/gifts'
          }
        ]
      }
    ]
  }],
  silver: [{
    title: 'menu.silver_jewelry',
    to: '/silver',
    metal: 'silver',
    menu: [
      {
        title: 'menu.women',
        to: 'women',
        key: 'women-silver',
        subMenu: [
          {
            title: 'menu.ring',
            to: '/silver/ring/women',
            submenu: [
              {
                title: 'menu.ring',
                to: '/silver/ring/women'
              },
              {
                title: 'menu.wed_ring',
                to: '/silver/wed_ring/women'
              },
              {
                title: 'menu.eng_ring',
                to: '/silver/eng_ring/women'
              }
            ]
          },
          {
            title: 'menu.earring',
            to: '/silver/earring/women'
          },
          {
            title: 'menu.bracelet',
            to: '/silver/bracelet/women',
            submenu: [
              {
                title: 'menu.hard_bracelet',
                to: '/silver/hard_bracelet/women'
              },
              {
                title: 'menu.chain_bracelet',
                to: '/silver/chain_bracelet/women'
              }
            ]
          },
          {
            title: 'menu.necklace',
            to: '/silver/necklace/women',
            submenu: [
              {
                title: 'menu.necklace',
                to: '/silver/necklace/women'
              },
              {
                title: 'menu.chain',
                to: '/silver/chain/women'
              }
            ]
          },
          {
            title: 'menu.set',
            to: '/silver/set/women'
          },
          {
            title: 'menu.accessories',
            to: '/silver/accessories/women'
          },
          {
            title: 'menu.pendant',
            to: '/silver/pendant/women',
            submenu: [
              {
                title: 'menu.pendant',
                to: '/silver/pendant/women'
              },
              {
                title: 'menu.cross',
                to: '/silver/cross/women'
              }
            ]
          }
        ]
      },
      {
        title: 'menu.men',
        to: 'men',
        key: 'men-silver',
        subMenu: [
          {
            title: 'menu.ring',
            to: '/silver/ring/men',
            submenu: [
              {
                title: 'menu.ring',
                to: '/silver/ring/men'
              },
              {
                title: 'menu.wed_ring',
                to: '/silver/wed_ring/men'
              }
            ]
          },
          {
            title: 'menu.bracelet',
            to: '/silver/bracelet/men',
            submenu: [
              {
                title: 'menu.hard_bracelet',
                to: '/silver/hard_bracelet/men'
              },
              {
                title: 'menu.chain_bracelet',
                to: '/silver/chain_bracelet/men'
              }
            ]
          },
          {
            title: 'menu.necklace',
            to: '/silver/necklace/men',
            submenu: [
              {
                title: 'menu.necklace',
                to: '/silver/necklace/men'
              },
              {
                title: 'menu.chain',
                to: '/silver/chain/men'
              }
            ]
          },
          {
            title: 'menu.set',
            to: '/silver/set/men'
          },
          {
            title: 'menu.accessories',
            to: '/silver/accessories/men'
          },
          {
            title: 'menu.pendant',
            to: '/silver/pendant/men',
            submenu: [
              {
                title: 'menu.pendant',
                to: '/silver/pendant/men'
              },
              {
                title: 'menu.cross',
                to: '/silver/cross/men'
              }
            ]
          }
        ]
      },
      {
        title: 'menu.kids',
        to: 'kids',
        key: 'children-silver',
        subMenu: [
          {
            title: 'menu.ring',
            to: '/silver/ring/kids'
          },
          {
            title: 'menu.earring',
            to: '/silver/earring/kids'
          },
          {
            title: 'menu.bracelet',
            to: '/silver/bracelet/kids',
            submenu: [
              {
                title: 'menu.hard_bracelet',
                to: '/silver/hard_bracelet/kids'
              },
              {
                title: 'menu.chain_bracelet',
                to: '/silver/chain_bracelet/kids'
              }
            ]
          },
          {
            title: 'menu.necklace',
            to: '/silver/necklace/kids',
            submenu: [
              {
                title: 'menu.necklace',
                to: '/silver/necklace/kids'
              },
              {
                title: 'menu.chain',
                to: '/silver/chain/kids'
              }
            ]
          },
          {
            title: 'menu.set',
            to: '/silver/set/kids'
          },
          {
            title: 'menu.accessories',
            to: '/silver/accessories/kids'
          },
          {
            title: 'menu.pendant',
            to: '/silver/pendant/kids',
            submenu: [
              {
                title: 'menu.pendant',
                to: '/silver/pendant/kids'
              },
              {
                title: 'menu.cross',
                to: '/silver/cross/kids'
              }
            ]
          }
        ]
      },
      {
        title: 'menu.accessories',
        to: 'accessories',
        key: 'accessories-silver',
        subMenu: [
          {
            title: 'menu.watches',
            to: '/silver/watches/accessories'
          },
          {
            title: 'menu.brooch',
            to: '/silver/brooch/accessories'
          },
          {
            title: 'menu.other',
            to: '/silver/other/accessories'
          }
        ]
      },
      {
        title: 'menu.souvenir',
        to: 'souvenir',
        key: 'souvenir-silver',
        subMenu: [
          {
            title: 'menu.silver_bars',
            to: '/silver/bars/gifts'
          },
          {
            title: 'menu.book',
            to: '/silver/book/gifts'
          },
          {
            title: 'menu.other',
            to: '/silver/other/gifts'
          }
        ]
      }
    ]
  }]
}

export const category = ['jewelry', 'watches', 'souvenir']
export const types = [
  'ring',
  'earring',
  'set',
  'eng_ring',
  'wed_ring',
  'pendant',
  'cross',
  'necklace',
  'chain',
  'other',
  'bracelet',
  'hard_bracelet',
  'chain_bracelet',
  'watches',
  'brooch',
  'bars',
  'book',
  'accessories',
  'gifts'
]
export const genders = ['women', 'men', 'boy', 'girl']
export const subcategory = ['women', 'men', 'kids', 'accessories', 'gifts']
export const metals = ['gold', 'silver', 'other']
