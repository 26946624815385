export default {
  state: () => {
    return {}
  },
  mutations: {},
  actions: {
    async post ({ commit }, body) {
      await this.$api.offer.post(body)
    }
  },
  namespaced: true
}
