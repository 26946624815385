import { CATEGORYS, GENDERS, METALS, SOUVENIR_TYPE } from '~/core/utils/enums'
import { purityTypes } from '~/core/utils/BuyerAccount'

export const specialization = [
  {
    label: 'menu.repair',
    title: 'menu.repair',
    value: 'repair',
    type: 'checkbox',
    queryName: 'specialization',
    id: 1
  },
  {
    label: 'menu.watchmaker',
    title: 'menu.watchmaker',
    value: 'clockworker',
    type: 'checkbox',
    queryName: 'specialization',
    id: 2
  },

  // {
  //   value: 'polishing',
  //   label: 'menu.polisher',
  //   title: 'menu.polisher',
  //   type: 'checkbox',
  //   queryName: 'specialization',
  //   id: 3
  // },
  // {
  //   value: 'golden_water',
  //   title: 'menu.golden_waterer',
  //   label: 'menu.golden_waterer',
  //   type: 'checkbox',
  //   queryName: 'specialization',
  //   id: 4
  // },
  {
    value: 'laser_engraving',
    label: 'menu.laser_engraving',
    title: 'menu.laser_engraving',
    type: 'checkbox',
    queryName: 'specialization',
    id: 5
  },
  // {
  //   value: 'casting',
  //   label: 'menu.casting',
  //   title: 'menu.casting',
  //   type: 'checkbox',
  //   queryName: 'specialization',
  //   id: 6
  // },
  // {
  //   value: 'resize',
  //   label: 'menu.resize',
  //   title: 'menu.resize',
  //   type: 'checkbox',
  //   queryName: 'specialization',
  //   id: 7
  // },

  {
    value: 'laser_inspection',
    label: 'menu.laser_inspection',
    title: 'menu.laser_inspection',
    type: 'checkbox',
    queryName: 'specialization',
    id: 8
  },
  // {
  //   value: 'calibration',
  //   label: 'menu.calibration',
  //   title: 'menu.calibration',
  //   type: 'checkbox',
  //   queryName: 'specialization',
  //   id: 9
  // },
  // {
  //   value: 'rhodium',
  //   label: 'menu.rhodium',
  //   title: 'menu.rhodium',
  //   type: 'checkbox',
  //   queryName: 'specialization',
  //   id: 10
  // },
  // {
  //   value: 'chemical_analysis',
  //   label: 'menu.chemical_analysis',
  //   title: 'menu.chemical_analysis',
  //   type: 'checkbox',
  //   queryName: 'specialization',
  //   id: 11
  // },
  // {
  //   value: '3d_modeling',
  //   label: 'menu.modeling_3D',
  //   title: 'menu.modeling_3D',
  //   type: 'checkbox',
  //   queryName: 'specialization',
  //   id: 12
  // },
  {
    value: 'installation_of_stones',
    label: 'menu.installation_of_stones',
    title: 'menu.installation_of_stones',
    type: 'checkbox',
    queryName: 'specialization',
    id: 13
  }
  // {
  //   value: 'growing_candles',
  //   label: 'menu.growing_candles',
  //   title: 'menu.growing_candles',
  //   type: 'checkbox',
  //   queryName: 'specialization',
  //   id: 14
  // }
]

export const shoppingCenter = [
  {
    id: 1,
    text: 'yerevan',
    title: 'region.yerevan',
    img: 'Yerevan.jpg',
    options: [
      {
        id: 1,
        shoppingCenterName: 'market.gold_center',
        img: 'gold-center.webp',
        title: 'market.gold_center',
        value: 'gold_center',
        type: 'checkbox',
        queryName: 'market'
      },
      {
        id: 2,
        shoppingCenterName: 'market.dalma_gold_center',
        img: 'Dalma-Gold-center.webp',
        title: 'market.dalma_gold_center',
        value: 'dalma_gold_center',
        type: 'checkbox',
        queryName: 'market'
      },
      {
        id: 3,
        shoppingCenterName: 'market.tashir_gold_center',
        img: 'Tashir-Gold-center.webp',
        title: 'market.tashir_gold_center',
        value: 'tashir_gold_center',
        type: 'checkbox',
        queryName: 'market'
      },
      {
        id: 4,
        shoppingCenterName: 'market.malatia_gold_center',
        img: 'Malatia-Gold-center.webp',
        title: 'market.malatia_gold_center',
        value: 'malatia_gold_center',
        type: 'checkbox',
        queryName: 'market'
      },
      {
        id: 5,
        shoppingCenterName: 'market.rossia_gold_center',
        img: 'rossia_gold_center.webp',
        title: 'market.rossia_gold_center',
        value: 'rossia_gold_center',
        type: 'checkbox',
        queryName: 'market'
      },
      {
        id: 6,
        shoppingCenterName: 'market.other',
        img: 'other-shop.webp',
        title: 'market.other',
        value: 'other',
        type: 'checkbox',
        queryName: 'market'
      }
    ]
  },
  {
    id: 2,
    text: 'aragacotn',
    title: 'region.aragacotn',
    img: 'Aragacotn.jpg',
    options: []
  },
  {
    id: 3,
    text: 'ararat',
    title: 'region.ararat',
    img: 'Ararat.jpg',
    options: []
  },
  {
    id: 4,
    text: 'armavir',
    title: 'region.armavir',
    img: 'Armavir.jpg',
    options: [
      {
        id: 6,
        shoppingCenterName: 'market.other',
        img: 'other-shop.webp',
        title: 'market.other',
        value: 'other',
        type: 'checkbox',
        queryName: 'market'
      }
    ]
  },
  {
    id: 5,
    text: 'gegharkunik',
    title: 'region.gegharkunik',
    img: 'Gegharkunik.jpg',
    options: []
  },
  {
    id: 6,
    text: 'kotayk',
    title: 'region.kotayk',
    img: 'Kotayk.jpg',
    options: [
      {
        id: 1,
        shoppingCenterName: 'market.multi_gold',
        img: 'multi_gold.webp',
        title: 'market.multi_gold',
        value: 'multi_gold',
        type: 'checkbox',
        queryName: 'market'
      }
    ]
  },
  {
    id: 7,
    text: 'lori',
    title: 'region.lori',
    img: 'lori.jpg',
    options: []
  },
  {
    id: 8,
    text: 'shirak',
    title: 'region.shirak',
    img: 'Shirak.jpg',
    options: []
  },
  {
    id: 9,
    text: 'syunik',
    title: 'region.syunik',
    img: 'Syunik.jpg',
    options: []
  },
  {
    id: 10,
    text: 'tavush',
    title: 'region.tavush',
    img: 'Tavush.jpg',
    options: []
  },
  {
    id: 11,
    text: 'vayotc dzor',
    title: 'region.vayotc_dzor',
    img: 'VayotcDzor.jpg',
    options: []
  }
  // {
  //   id: 12,
  //   text: 'artsakh',
  //   title: 'region.artsakh',
  //   img: 'Artsakh.jpg',
  //   options: []
  // }
]

export const metalsTypes = {
  title: 'filters.metal',
  type: 'radio',
  queryName: 'metal',
  key: 'metal',
  id: 122112,
  access: [CATEGORYS.WATCHES, CATEGORYS.JEWELRY, CATEGORYS.SOUVENIR],
  accessMetal: [METALS.GOLD, METALS.OTHER, METALS.SILVER],
  accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL],
  options: [
    {
      title: 'basic.gold',
      value: 'gold',
      access: [CATEGORYS.WATCHES, CATEGORYS.JEWELRY, CATEGORYS.SOUVENIR],
      accessMetal: [METALS.GOLD, METALS.OTHER, METALS.SILVER],
      accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
    },
    {
      title: 'basic.silver',
      value: 'silver',
      access: [CATEGORYS.WATCHES, CATEGORYS.JEWELRY, CATEGORYS.SOUVENIR],
      accessMetal: [METALS.GOLD, METALS.OTHER, METALS.SILVER],
      accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
    },
    {
      title: 'menu.other',
      value: 'other',
      access: [CATEGORYS.WATCHES, CATEGORYS.SOUVENIR],
      accessMetal: [METALS.GOLD, METALS.OTHER, METALS.SILVER],
      accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
    }
  ]
}

export const regions = shoppingCenter.map((e, index) => {
  return {
    access: [CATEGORYS.WATCHES, CATEGORYS.JEWELRY, CATEGORYS.SOUVENIR],
    accessMetal: [METALS.GOLD, METALS.OTHER, METALS.SILVER],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL],
    title: e.title,
    value: e?.text?.toLowerCase(),
    type: 'checkbox',
    queryName: 'regions',
    id: e.id,
    options: e.options
  }
})

export const Carat = [
  {
    title: '0.18 - 0.22',
    value: '0.18_0.22',
    id: 0,
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    accessMetal: [METALS.GOLD],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '0.23 - 0.29',
    value: '0.23_0.29',
    id: 1,
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    accessMetal: [METALS.GOLD],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '0.3 - 0.39',
    value: '0.3_0.39',
    id: 2,
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    accessMetal: [METALS.GOLD],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '0.4 - 0.49',
    value: '0.4_0.49',
    id: 3,
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    accessMetal: [METALS.GOLD],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '0.5 - 0.69',
    value: '0.5_0.69',
    id: 4,
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    accessMetal: [METALS.GOLD],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '0.7 - 0.89',
    value: '0.7_0.89',
    id: 5,
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    accessMetal: [METALS.GOLD],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '0.9 - 0.99',
    value: '0.9_0.99',
    id: 6,
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    accessMetal: [METALS.GOLD],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '1 ct and more',
    value: '1_ct_and_more',
    id: 7,
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    accessMetal: [METALS.GOLD],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  }
]

export const souvenirFilters = [
  {
    title: 'filters.price',
    type: 'slider',
    queryName: 'price',
    maxValue: 1350,
    minValue: 0,
    step: 5,
    id: 1,
    simbol: '$',
    access: [CATEGORYS.SOUVENIR]
  },

  metalsTypes,
  {
    access: [CATEGORYS.SOUVENIR],
    typeAccess: [SOUVENIR_TYPE.BARS],
    title: 'filters.weight',
    type: 'slider',
    queryName: 'weight',
    maxValue: 1000,
    minValue: 0,
    step: 0.1,
    id: 'weight',
    simbol: 'gr'
  },
  {
    title: 'filters.type',
    type: 'radio',
    queryName: 'type',
    key: 'type',
    id: 2,
    access: [CATEGORYS.SOUVENIR],
    options: [
      {
        title: 'basic.coin',
        value: 'coin',
        accessMetal: [METALS.SILVER, METALS.GOLD]
      },
      {
        title: 'basic.bars',
        value: 'bars',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'basic.antique',
        value: 'antique',
        accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER]
      }
    ]
  },
  {
    access: [CATEGORYS.SOUVENIR],
    typeAccess: [SOUVENIR_TYPE.BARS],
    title: 'filters.name',
    type: 'checkbox',
    queryName: 'barsName',
    key: 'barsName',
    id: 'barsName',
    options: [
      {
        title: 'P.A.M.P',
        value: 'P.A.M.P',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'Heraeus',
        value: 'heraeus',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'Munze Osterich',
        value: 'munze_osterich',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'Argor Heraeus',
        value: 'argor_heraeus',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'Perth Mint',
        value: 'perth_mint',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'Valcambi',
        value: 'valcambi',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'UBS',
        value: 'usb',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'Russia',
        value: 'russia',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'Commerzbank',
        value: 'commerzbank',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'Credit Suisse',
        value: 'credit_suisse',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'Other',
        value: 'other',
        accessMetal: [METALS.GOLD]
      }
    ]
  },
  // {
  //   access: [CATEGORYS.SOUVENIR],
  //   title: 'filters.brand',
  //   type: 'checkbox',
  //   queryName: 'store',
  //   id: 9,
  //   options: [
  //     {
  //       title: 'menu.other',
  //       value: 'other',
  //       access: [CATEGORYS.SOUVENIR],
  //       accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     }
  //   ]
  // },
  {
    access: [CATEGORYS.SOUVENIR],
    typeAccess: [SOUVENIR_TYPE.COIN],
    title: 'auth.country',
    type: 'checkbox',
    queryName: 'country',
    key: 'country',
    id: 'country',
    options: [
      {
        title: 'country.armenia',
        value: 'armenian',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'country.russian',
        value: 'russian',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'country.english',
        value: 'english',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'country.french',
        value: 'french',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'country.lebanon',
        value: 'lebanon',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'country.american',
        value: 'american',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'colors.other',
        value: 'other',
        accessMetal: [METALS.GOLD]
      }
    ]
  },
  {
    title: 'filters.fineness',
    type: 'checkbox',
    queryName: 'fineness',
    id: 99,
    access: [CATEGORYS.SOUVENIR],
    options: [
      {
        title: 'Gold | 999.9',
        value: 'g999.9',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'Gold | 916',
        value: 'g916',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'Gold | 900',
        value: 'g900',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'Gold | 875',
        value: 'g875',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'Gold | 750',
        value: 'g750',
        accessMetal: [METALS.GOLD]
      },
      {
        title: 'Gold | 585',
        value: 'g585',
        accessMetal: [METALS.GOLD]
      },
      {
        title: '999',
        value: 's999',
        accessMetal: [METALS.SILVER]
      },
      {
        title: '925',
        value: 's925',
        accessMetal: [METALS.SILVER]
      },
      {
        title: '900',
        value: 's900',
        accessMetal: [METALS.SILVER]
      }
    ]
  },
  // {
  //   access: [CATEGORYS.SOUVENIR],
  //   typeAccess: [SOUVENIR_TYPE.BARS],
  //   title: 'filters.weight',
  //   type: 'checkbox',
  //   queryName: 'weight',
  //   key: 'weight',
  //   id: 'Weight',
  //   maxValue: 100,
  //   minValue: 0.1,
  //   step: 0.1,
  //   simbol: 'g',
  //   options: [
  //     {
  //       title: '1',
  //       value: '1',
  //       accessMetal: [METALS.GOLD]
  //     },
  //     {
  //       title: '2',
  //       value: '2',
  //       accessMetal: [METALS.GOLD]
  //     },
  //     {
  //       title: '2.5',
  //       value: '2.5',
  //       accessMetal: [METALS.GOLD]
  //     },
  //     {
  //       title: '5',
  //       value: '5',
  //       accessMetal: [METALS.GOLD]
  //     },
  //     {
  //       title: '10',
  //       value: '10',
  //       accessMetal: [METALS.GOLD]
  //     },
  //     {
  //       title: '15.55',
  //       value: '15.55',
  //       accessMetal: [METALS.GOLD]
  //     },
  //     {
  //       title: '20',
  //       value: '20',
  //       accessMetal: [METALS.GOLD]
  //     },
  //     {
  //       title: '31.1',
  //       value: '31.1',
  //       accessMetal: [METALS.GOLD]
  //     },
  //     {
  //       title: '50',
  //       value: '50',
  //       accessMetal: [METALS.GOLD]
  //     },
  //     {
  //       title: '62.2',
  //       value: '62.2',
  //       accessMetal: [METALS.GOLD]
  //     },
  //     {
  //       title: '100',
  //       value: '100',
  //       accessMetal: [METALS.GOLD]
  //     },
  //     {
  //       title: 'Other',
  //       value: 'other',
  //       accessMetal: [METALS.GOLD]
  //     }
  //   ]
  // },
  // {
  //   access: [CATEGORYS.SOUVENIR],
  //   typeAccess: [SOUVENIR_TYPE.BARS],
  //   title: 'Certificate',
  //   type: 'radio',
  //   queryName: 'certificate',
  //   key: 'certificate',
  //   id: 12,
  //   options: [
  //     {
  //       title: 'Yes',
  //       value: 'yes',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD, METALS.OTHER],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'No',
  //       value: 'no',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD, METALS.OTHER],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     }
  //   ]
  // },
  {
    access: [CATEGORYS.SOUVENIR],
    title: 'filters.region',
    type: 'radio',
    queryName: 'region',
    key: 'region',
    id: 123456789,
    options: regions
  },
  {
    access: [CATEGORYS.SOUVENIR],
    title: 'filters.market',
    type: 'radio',
    queryName: 'market',
    key: 'market',
    id: 9876543219988,
    options: [
      {
        access: [CATEGORYS.WATCHES, CATEGORYS.JEWELRY, CATEGORYS.SOUVENIR],
        accessMetal: [METALS.GOLD, METALS.OTHER, METALS.SILVER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL],
        title: 'Gold center',
        value: 'gold_center',
        type: 'checkbox',
        queryName: 'market',
        id: 1
      },

      {
        access: [CATEGORYS.WATCHES, CATEGORYS.JEWELRY, CATEGORYS.SOUVENIR],
        accessMetal: [METALS.GOLD, METALS.OTHER, METALS.SILVER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL],
        title: 'Dalma Gold center',
        value: 'dalma_gold_center',
        type: 'checkbox',
        queryName: 'market',
        id: 2
      },
      {
        access: [CATEGORYS.WATCHES, CATEGORYS.JEWELRY, CATEGORYS.SOUVENIR],
        accessMetal: [METALS.GOLD, METALS.OTHER, METALS.SILVER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL],
        title: 'Tashir Gold center',
        value: 'tashir_gold_center',
        type: 'checkbox',
        queryName: 'market',
        id: 3
      },
      {
        access: [CATEGORYS.WATCHES, CATEGORYS.JEWELRY, CATEGORYS.SOUVENIR],
        accessMetal: [METALS.GOLD, METALS.OTHER, METALS.SILVER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL],
        title: 'Malatia-Gold-center',
        value: 'malatia_gold_center',
        type: 'checkbox',
        queryName: 'market',
        id: 4
      },
      {
        access: [CATEGORYS.WATCHES, CATEGORYS.JEWELRY, CATEGORYS.SOUVENIR],
        accessMetal: [METALS.GOLD, METALS.OTHER, METALS.SILVER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL],
        title: 'Rossia-Gold-center',
        value: 'rossia_gold_center',
        type: 'checkbox',
        queryName: 'market',
        id: 5
      },
      {
        access: [CATEGORYS.WATCHES, CATEGORYS.JEWELRY, CATEGORYS.SOUVENIR],
        accessMetal: [METALS.GOLD, METALS.OTHER, METALS.SILVER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL],
        title: 'Other',
        value: 'other',
        type: 'checkbox',
        queryName: 'market',
        id: 6
      }
    ]
  }
]

export const filters = [
  {
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    title: 'filters.price',
    type: 'slider',
    queryName: 'price',
    maxValue: 1350,
    minValue: 0,
    step: 5,
    id: 1,
    simbol: '$'
  },
  metalsTypes,
  {
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    title: 'filters.weight',
    type: 'slider',
    queryName: 'weight',
    maxValue: 1000,
    minValue: 0,
    step: 0.1,
    id: 'weight',
    simbol: 'gr'
  },
  {
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    title: 'filters.for_whom',
    type: 'radio',
    queryName: 'gender',
    key: 'gender',
    id: 'gender',
    options: [
      {
        title: 'menu.women',
        value: 'women',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'menu.men',
        value: 'men',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        disablet: true,
        title: 'menu.kids',
        access: [CATEGORYS.JEWELRY],
        accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'menu.boy',
        value: 'boy',
        access: [CATEGORYS.JEWELRY],
        accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'menu.girl',
        value: 'girl',
        access: [CATEGORYS.JEWELRY],
        accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      }
    ]
  },
  {
    access: [CATEGORYS.JEWELRY],
    title: 'filters.type',
    type: 'radio',
    queryName: 'type',
    key: 'type',
    id: 3,
    options: [
      {
        title: 'menu.ring',
        value: 'ring',
        access: [CATEGORYS.JEWELRY],
        accessMetal: [METALS.SILVER, METALS.GOLD],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'menu.wed_ring',
        value: 'wed_ring',
        access: [CATEGORYS.JEWELRY],
        accessMetal: [METALS.GOLD],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN]
      },
      {
        title: 'menu.eng_ring',
        value: 'eng_ring',
        access: [CATEGORYS.JEWELRY],
        accessMetal: [METALS.GOLD],
        accessGender: [GENDERS.WOMEN]
      },
      {
        title: 'menu.necklace',
        value: 'necklace',
        access: [CATEGORYS.JEWELRY],
        accessMetal: [METALS.SILVER, METALS.GOLD],
        accessGender: [GENDERS.WOMEN]
      },
      {
        title: 'menu.cross',
        value: 'cross',
        access: [CATEGORYS.JEWELRY],
        accessMetal: [METALS.SILVER, METALS.GOLD],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN]
      },
      {
        title: 'menu.set',
        value: 'set',
        access: [CATEGORYS.JEWELRY],
        accessMetal: [METALS.SILVER, METALS.GOLD],
        accessGender: [GENDERS.WOMEN]
      },
      {
        title: 'menu.earring',
        value: 'earring',
        access: [CATEGORYS.JEWELRY],
        accessMetal: [METALS.SILVER, METALS.GOLD],
        accessGender: [GENDERS.WOMEN, GENDERS.GIRL]
      },
      {
        title: 'menu.pendant',
        value: 'pendant',
        access: [CATEGORYS.JEWELRY],
        accessMetal: [METALS.SILVER, METALS.GOLD],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'menu.chain',
        value: 'chain',
        access: [CATEGORYS.JEWELRY],
        accessMetal: [METALS.SILVER, METALS.GOLD],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN]
      },
      {
        title: 'menu.bracelet',
        value: 'bracelet',
        access: [CATEGORYS.JEWELRY],
        accessMetal: [METALS.SILVER, METALS.GOLD],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.GIRL, GENDERS.BOY]
      },
      {
        title: 'menu.chain_bracelet',
        value: 'chain_bracelet',
        access: [CATEGORYS.JEWELRY],
        accessMetal: [METALS.SILVER, METALS.GOLD],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.GIRL, GENDERS.BOY]
      },
      {
        title: 'menu.other',
        value: 'other',
        access: [CATEGORYS.JEWELRY],
        accessMetal: [METALS.SILVER, METALS.GOLD],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.GIRL, GENDERS.BOY]
      }
    ]
  },
  {
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    title: 'filters.fineness',
    type: 'checkbox',
    queryName: 'fineness',
    id: 4,
    options: purityTypes
  },
  {
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    title: 'filters.color',
    type: 'checkbox',
    queryName: 'colors',
    id: 2,
    options: [
      {
        title: 'colors.silver',
        value: 'silver',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.SILVER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'colors.gold_plated',
        value: 'gold_plated',
        access: [CATEGORYS.JEWELRY],
        accessMetal: [METALS.SILVER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'colors.gold',
        value: 'gold',
        access: [CATEGORYS.WATCHES],
        accessMetal: [METALS.SILVER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'colors.yellow',
        value: 'yellow',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'colors.white',
        value: 'white',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'colors.red',
        value: 'red',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'colors.rose',
        value: 'rose',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'colors.rose_gold',
        value: 'rose_gold',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.SILVER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'colors.rhodium',
        value: 'rhodium',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.SILVER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'colors.two_tone',
        value: 'two_tone',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'colors.three_tone',
        value: 'three_tone',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      // {
      //   title: 'Yellow-White',
      //   value: 'yellow-white',
      //   access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
      //   accessMetal: [],
      //   accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      // },
      // {
      //   title: 'Yellow-Red',
      //   value: 'yellow-red',
      //   access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
      //   accessMetal: [],
      //   accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      // },
      // {
      //   title: 'Yellow-Rose',
      //   value: 'yellow-rose',
      //   access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
      //   accessMetal: [],
      //   accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      // },
      // {
      //   title: 'White-Red',
      //   value: 'white-red',
      //   access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
      //   accessMetal: [],
      //   accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      // },
      // {
      //   title: 'White-Rose',
      //   value: 'white-rose',
      //   access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
      //   accessMetal: [],
      //   accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      // },
      // {
      //   title: 'Red-Rose',
      //   value: 'red-rose',
      //   access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
      //   accessMetal: [],
      //   accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      // },
      {
        title: 'colors.other',
        value: 'other',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      }
    ]
  },
  {
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    title: 'filters.diamond',
    type: 'radio',
    key: 'stoneCut',
    queryName: 'stoneCut',
    id: 10,
    options: [
      {
        title: 'filters.round',
        value: 'round',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'filters.fantasty',
        value: 'fantasy',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'filters.without_diamond',
        value: 'none',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      }
    ]
  },
  {
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    title: 'filters.carat',
    type: 'checkbox',
    queryName: 'stoneCarat',
    id: 11,
    options: Carat
  },
  {
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    title: 'filters.certificate',
    type: 'radio',
    queryName: 'stoneCertificate',
    key: 'stoneCertificate',
    id: 12,
    options: [
      {
        title: 'basic.yes',
        value: 'yes',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'basic.no',
        value: 'no',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      }
    ]
  },
  {
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    title: 'filters.gemstone',
    type: 'radio',
    queryName: 'gemstone',
    id: 5,
    key: 'gemstone',
    options: [
      {
        title: 'basic.yes',
        value: 'yes',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'basic.no',
        value: 'no',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      }
    ]
  },

  {
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    title: 'filters.origin',
    type: 'checkbox',
    queryName: 'origin',
    id: 6,
    options: [
      {
        title: 'filters.armenian',
        value: 'armenian',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'filters.imported',
        value: 'imported',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      }
    ]
  },
  {
    access: [CATEGORYS.WATCHES],
    title: 'filters.movement_type',
    type: 'checkbox',
    queryName: 'movement_type',
    id: 7,
    options: [
      {
        title: 'basic.mechanical',
        value: 'mechanical',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'basic.automatic',
        value: 'automatic',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'menu.other',
        value: 'other',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      }
    ]
  },
  {
    access: [CATEGORYS.WATCHES],
    title: 'filters.bracelet_belt',
    type: 'checkbox',
    queryName: 'bracelet_belt',
    id: 8,
    options: [
      {
        title: 'basic.chain',
        value: 'chain',
        access: [CATEGORYS.WATCHES],
        accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'basic.belt',
        value: 'automatic',
        access: [CATEGORYS.WATCHES],
        accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      },
      {
        title: 'menu.other',
        value: 'other',
        access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
        accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
      }
    ]
  },
  // {
  //   access: [CATEGORYS.WATCHES, CATEGORYS.JEWELRY, CATEGORYS.SOUVENIR],
  //   title: 'filters.brand',
  //   type: 'checkbox',
  //   queryName: 'store',
  //   id: 9,
  //   options: [
  //     {
  //       title: 'menu.other',
  //       value: 'other',
  //       access: [CATEGORYS.WATCHES, CATEGORYS.JEWELRY, CATEGORYS.SOUVENIR],
  //       accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     }
  //   ]
  // },
  {
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    title: 'filters.region',
    type: 'radio',
    queryName: 'region',
    key: 'region',
    id: 123456789,
    options: regions
  },
  {
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    title: 'filters.market',
    type: 'radio',
    queryName: 'market',
    key: 'market',
    id: 987654321998,
    options: [
      {
        access: [CATEGORYS.WATCHES, CATEGORYS.JEWELRY],
        accessMetal: [METALS.GOLD, METALS.OTHER, METALS.SILVER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL],
        title: 'market.gold_center',
        value: 'gold_center',
        type: 'checkbox',
        queryName: 'market',
        id: 1
      },
      {
        access: [CATEGORYS.WATCHES, CATEGORYS.JEWELRY],
        accessMetal: [METALS.GOLD, METALS.OTHER, METALS.SILVER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL],
        title: 'market.dalma_gold_center',
        value: 'dalma_gold_center',
        type: 'checkbox',
        queryName: 'market',
        id: 2
      },
      {
        access: [CATEGORYS.WATCHES, CATEGORYS.JEWELRY],
        accessMetal: [METALS.GOLD, METALS.OTHER, METALS.SILVER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL],
        title: 'market.tashir_gold_center',
        value: 'tashir_gold_center',
        type: 'checkbox',
        queryName: 'market',
        id: 3
      },
      {
        access: [CATEGORYS.WATCHES, CATEGORYS.JEWELRY],
        accessMetal: [METALS.GOLD, METALS.OTHER, METALS.SILVER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL],
        title: 'market.malatia_gold_center',
        value: 'malatia_gold_center',
        type: 'checkbox',
        queryName: 'market',
        id: 4
      },
      {
        access: [CATEGORYS.WATCHES, CATEGORYS.JEWELRY, CATEGORYS.SOUVENIR],
        accessMetal: [METALS.GOLD, METALS.OTHER, METALS.SILVER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL],
        title: 'market.rossia_gold_center',
        value: 'rossia_gold_center',
        type: 'checkbox',
        queryName: 'market',
        id: 5
      },
      {
        access: [CATEGORYS.WATCHES, CATEGORYS.JEWELRY, CATEGORYS.SOUVENIR],
        accessMetal: [METALS.GOLD, METALS.OTHER, METALS.SILVER],
        accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL],
        title: 'market.other',
        value: 'other',
        type: 'checkbox',
        queryName: 'market',
        id: 6
      }
    ]
  }

  // {
  //   access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //   title: 'Stone Color',
  //   type: 'checkbox',
  //   queryName: 'stoneColor',
  //   id: 13,
  //   options: [
  //     {
  //       title: 'D',
  //       value: 'd',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'E',
  //       value: 'e',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'F',
  //       value: 'f',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'G',
  //       value: 'g',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'I',
  //       value: 'i',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'J',
  //       value: 'j',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'K',
  //       value: 'k',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     }
  //   ]
  // },
  // {
  //   access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //   title: 'Stone Clarity',
  //   type: 'checkbox',
  //   queryName: 'stone_clarity',
  //   id: 14,
  //   options: [
  //     {
  //       title: 'IF',
  //       value: 'if',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'VVS1',
  //       value: 'vvs1',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'VS1',
  //       value: 'vs1',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'VS2',
  //       value: 'vs2',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'SI1',
  //       value: 'si1',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'SI2',
  //       value: 'si2',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'SI3',
  //       value: 'si3',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     }
  //   ]
  // }
  // {
  //   access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //   title: 'Stone Cut',
  //   type: 'checkbox',
  //   queryName: 'stoneCut',
  //   id: 15,
  //   options: [
  //     {
  //       title: 'Half Moon',
  //       value: 'half_moon',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'Calf',
  //       value: 'calf',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'Trillion Curved',
  //       value: 'trillion_curved',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'Heart',
  //       value: 'heart',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'Radiant Square',
  //       value: 'radiant_square',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'Radiant',
  //       value: 'radiant',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'Emerald',
  //       value: 'emerald',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'Octagon',
  //       value: 'octagon',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'Emerald Square',
  //       value: 'emerald_square',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'Marquise',
  //       value: 'marquise',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'Pear',
  //       value: 'pear',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'Princess',
  //       value: 'Princess',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'Oval',
  //       value: 'oval',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'Cushion',
  //       value: 'cushion',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'Cushion-square',
  //       value: 'cushion_square',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     },
  //     {
  //       title: 'Round',
  //       value: 'round',
  //       access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
  //       accessMetal: [METALS.GOLD],
  //       accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  //     }
  //   ]
  // }
]

export const collections = [
  {
    title: 'menu.ring',
    to: '/ring',
    img: 'ring.webp',
    id: 1
  },
  {
    title: 'menu.earring',
    to: '/earring',
    img: 'earring.webp',
    id: 2
  },
  {
    title: 'menu.pendant',
    to: '/pendant',
    img: 'pendant.webp',
    id: 6
  },
  {
    title: 'menu.set',
    to: '/set',
    img: 'set.webp',
    id: 3
  },
  {
    title: 'menu.eng_ring',
    to: '/eng_ring',
    img: 'engagement_rings.webp',
    id: 4
  },
  {
    title: 'menu.wed_ring',
    to: '/wed_ring',
    img: 'wedding_rings.webp',
    id: 5
  },
  {
    title: 'menu.hard_bracelet',
    to: '/hard_bracelet',
    img: 'hard_bracelet.webp',
    id: 16
  },
  {
    title: 'menu.chain_bracelet',
    to: '/chain_bracelet',
    img: 'bracelets.webp',
    id: 10
  },
  {
    title: 'menu.cross',
    to: '/cross',
    img: 'crosses.webp',
    id: 7
  },
  {
    title: 'menu.chain',
    to: '/chain',
    img: 'chain.webp',
    id: 9
  },
  {
    title: 'menu.necklace',
    to: '/necklace',
    img: 'necklace.webp',
    id: 8
  },
  {
    title: 'menu.bracelet',
    to: '/bracelet',
    img: 'necklace.webp',
    id: 8
  },
  {
    title: 'menu.watches',
    to: '/watches',
    img: 'watches.webp',
    id: 12
  },
  {
    title: 'menu.souvenir',
    to: '/souvenir',
    img: 'souviner.webp',
    id: 13
  },
  {
    title: 'menu.other',
    to: '/other',
    img: 'other.webp',
    id: 16
  }
]

export const collection = [
  {
    title: 'menu.ring',
    to: '/ring',
    img: 'ring.webp',
    id: 1
  },
  {
    title: 'menu.earring',
    to: '/earring',
    img: 'earring.webp',
    id: 2
  },
  {
    title: 'menu.pendant',
    to: '/pendant',
    img: 'pendant.webp',
    id: 6
  },
  {
    title: 'menu.set',
    to: '/set',
    img: 'set.webp',
    id: 3
  },
  {
    title: 'menu.eng_ring',
    to: '/eng_ring',
    img: 'engagement_rings.webp',
    id: 4
  },
  {
    title: 'menu.wed_ring',
    to: '/wed_ring',
    img: 'wedding_rings.webp',
    id: 5
  }
]

export const servicesFilters = [
  {
    title: 'filters.services_different',
    type: 'checkbox',
    queryName: 'specialization',
    key: 'specialization',
    id: 999666858585,
    options: specialization
  },
  {
    title: 'filters.region',
    type: 'radio',
    queryName: 'region',
    key: 'region',
    id: 123456789,
    options: regions
  },
  {
    title: 'filters.market',
    type: 'radio',
    queryName: 'market',
    key: 'market',
    id: 987654321998,
    options: [
      {
        title: 'Gold center',
        value: 'gold_center',
        type: 'checkbox',
        queryName: 'market',
        id: 1
      },
      {
        title: 'Dalma Gold center',
        value: 'dalma_gold_center',
        type: 'checkbox',
        queryName: 'market',
        id: 2
      },
      {
        title: 'Tashir Gold center',
        value: 'tashir_gold_center',
        type: 'checkbox',
        queryName: 'market',
        id: 3
      },
      {
        title: 'Malatia-Gold-center',
        value: 'malatia_gold_center',
        type: 'checkbox',
        queryName: 'market',
        id: 4
      },
      {
        title: 'Rossia-Gold-center',
        value: 'rossia_gold_center',
        type: 'checkbox',
        queryName: 'market',
        id: 5
      },
      {
        title: 'Other',
        value: 'other',
        type: 'checkbox',
        queryName: 'market',
        id: 6
      }
    ]
  }
]

export const sellerFilters = [
  // {
  //   title: 'filters.brand',
  //   type: 'radio',
  //   queryName: 'store',
  //   key: 'store',
  //   id: 'store',
  //   options: [
  //     {
  //       title: 'menu.other',
  //       value: 'other',
  //       type: 'radio',
  //       queryName: 'store',
  //       id: 1,
  //     }
  //   ]
  // },
  {
    title: 'filters.region',
    type: 'radio',
    queryName: 'region',
    key: 'region',
    id: 123456789,
    options: regions
  },
  {
    title: 'filters.market',
    type: 'radio',
    queryName: 'market',
    key: 'market',
    id: 987654321998,
    options: [
      {
        title: 'Gold center',
        value: 'gold_center',
        type: 'checkbox',
        queryName: 'market',
        id: 1
      },
      {
        title: 'Dalma Gold center',
        value: 'dalma_gold_center',
        type: 'checkbox',
        queryName: 'market',
        id: 2
      },
      {
        title: 'Tashir Gold center',
        value: 'tashir_gold_center',
        type: 'checkbox',
        queryName: 'market',
        id: 3
      },
      {
        title: 'Malatia-Gold-center',
        value: 'malatia_gold_center',
        type: 'checkbox',
        queryName: 'market',
        id: 4
      },
      {
        title: 'Rossia-Gold-center',
        value: 'rossia_gold_center',
        type: 'checkbox',
        queryName: 'market',
        id: 5
      },
      {
        title: 'Other',
        value: 'other',
        type: 'checkbox',
        queryName: 'market',
        id: 6
      }
    ]
  }
]

export const userTypes = {
  access: [CATEGORYS.JEWELRY],
  title: 'basic.userType',
  type: 'radio',
  queryName: 'userType',
  key: 'userType',
  id: 'userType',
  options: [
    {
      title: 'auth.seller',
      key: 'userType',
      value: 'seller',
      access: [CATEGORYS.JEWELRY],
      accessMetal: [METALS.GOLD, METALS.SILVER],
      accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
    },
    {
      title: 'filters.manufacturer_seller',
      key: 'userType',
      value: 'manufacturer',
      access: [CATEGORYS.JEWELRY],
      accessMetal: [METALS.GOLD, METALS.SILVER],
      accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
    }
  ]
}

export const conditional = {
  access: [CATEGORYS.JEWELRY],
  title: 'filters.conditional',
  type: 'radio',
  queryName: 'conditional',
  key: 'conditional',
  id: 'conditional',
  options: [
    {
      title: 'filters.mounting',
      value: 'mounting',
      key: 'conditional',
      access: [CATEGORYS.JEWELRY],
      accessMetal: [METALS.GOLD],
      accessGender: [GENDERS.MEN, GENDERS.WOMEN]
    },
    {
      title: 'filters.ready',
      value: 'ready',
      key: 'conditional',
      access: [CATEGORYS.JEWELRY],
      accessMetal: [METALS.GOLD],
      accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
    }
  ]
}

export const sellerCategory = {
  title: 'menu.category',
  type: 'radio',
  queryName: 'category',
  key: 'category',
  id: 'category',
  options: [
    {
      accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
      value: 'jewelry-gold',
      title: 'menu.gold_jewelry'
    },
    {
      accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER],
      value: 'jewelry-silver',
      title: 'menu.silver_jewelry'
    },
    {
      title: 'menu.watches',
      value: 'watches',
      accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER]
    },
    {
      title: 'menu.souvenir',
      value: 'souvenir',
      accessMetal: [METALS.SILVER, METALS.GOLD, METALS.OTHER]
    }
  ]
}
