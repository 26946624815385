export const SUBCATEGORIES = {
  WOMEN: 'women',
  MEN: 'men',
  KIDS: 'kids',
  ACCESSORIES: 'accessories',
  GIFTS: 'gifts'
}

export const TYPES = {
  RING: 'ring',
  EARRING: 'earring',
  BRACELET: 'bracelet',
  NECKLACE: 'necklace',
  SET: 'set',
  ACCESSORIES: 'accessories',
  PENDANT: 'pendant',
  WATCH: 'watch',
  BROOCH: 'brooch',
  GOLDBARS: 'goldBars',
  BOOK: 'book',
  OTHER: 'other'
}

export const CATEGORYS = {
  JEWELRY: 'jewelry',
  WATCHES: 'watches',
  SOUVENIR: 'souvenir'
}

export const GENDERS = {
  WOMEN: 'women',
  MEN: 'men',
  BOY: 'boy',
  GIRL: 'girl'
}

export const METALS = {
  GOLD: 'gold',
  SILVER: 'silver',
  OTHER: 'other'
}

export const SOUVENIR_TYPE = {
  BARS: 'bars',
  COIN: 'coin',
  ANTIQUE: 'antique'
}

export const STONE_CUP_OPTIONS = [
  {
    title: 'basic.yes',
    value: 'yes',
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    accessMetal: [METALS.GOLD],
    accessGender: [
      GENDERS.MEN,
      GENDERS.WOMEN,
      GENDERS.BOY,
      GENDERS.GIRL
    ]
  },
  {
    title: 'basic.no',
    value: 'no',
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    accessMetal: [METALS.GOLD],
    accessGender: [
      GENDERS.MEN,
      GENDERS.WOMEN,
      GENDERS.BOY,
      GENDERS.GIRL
    ]
  }
]
