import { getQuery } from '~/core/utils/queryAPI'

export default $axios => ({
  async login (sendData) {
    const { query, body } = sendData
    const { data } = await $axios.post(`/auth/login?${getQuery(query)}`, body)
    return data
  },
  async register (registrationData) {
    const { data } = await $axios.post('/auth/register', registrationData)
    return data
  },

  async restore (restoreData) {
    const { data } = await $axios.post('/auth/restore', restoreData)
    return data
  },

  async confirmRestore (restoreData) {
    const { body, token } = restoreData
    const { data } = await $axios.post(`/auth/confirm_restore/${token}`, body)
    return data
  },

  async updatePassword (sendData) {
    const { data } = await $axios.put('/auth/update_password', sendData)
    return data
  },

  async verify (code) {
    const { data } = await $axios.post('/auth/verify', code)
    return data
  }
})
