<template>
  <a :href="templateTel(tel)" :class="classes">
    <slot />
  </a>
</template>

<script>
export default {
  props: ['tel', 'classes'],
  methods: {
    templateTel (tel) {
      tel = tel?.replace(/\D/g, '')
      return `tel:+${tel}`
    }
  }
}
</script>
