import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/modules/aja.js'), 'modules/aja.js')
  resolveStoreModules(require('../store/modules/auth.js'), 'modules/auth.js')
  resolveStoreModules(require('../store/modules/brand.js'), 'modules/brand.js')
  resolveStoreModules(require('../store/modules/cart.js'), 'modules/cart.js')
  resolveStoreModules(require('../store/modules/catalog.js'), 'modules/catalog.js')
  resolveStoreModules(require('../store/modules/categories.js'), 'modules/categories.js')
  resolveStoreModules(require('../store/modules/collection.js'), 'modules/collection.js')
  resolveStoreModules(require('../store/modules/favorite.js'), 'modules/favorite.js')
  resolveStoreModules(require('../store/modules/jewelry.js'), 'modules/jewelry.js')
  resolveStoreModules(require('../store/modules/main.js'), 'modules/main.js')
  resolveStoreModules(require('../store/modules/modals.js'), 'modules/modals.js')
  resolveStoreModules(require('../store/modules/offer.js'), 'modules/offer.js')
  resolveStoreModules(require('../store/modules/pre-order.js'), 'modules/pre-order.js')
  resolveStoreModules(require('../store/modules/product.js'), 'modules/product.js')
  resolveStoreModules(require('../store/modules/services.js'), 'modules/services.js')
  resolveStoreModules(require('../store/modules/shopping-center.js'), 'modules/shopping-center.js')
  resolveStoreModules(require('../store/modules/site-mode.js'), 'modules/site-mode.js')
  resolveStoreModules(require('../store/modules/souvenir.js'), 'modules/souvenir.js')
  resolveStoreModules(require('../store/modules/ui.js'), 'modules/ui.js')
  resolveStoreModules(require('../store/modules/user.js'), 'modules/user.js')
  resolveStoreModules(require('../store/modules/watches.js'), 'modules/watches.js')
  resolveStoreModules(require('../store/modules/buyer-account/index.js'), 'modules/buyer-account/index.js')
  resolveStoreModules(require('../store/modules/buyer-account/dashboard.js'), 'modules/buyer-account/dashboard.js')
  resolveStoreModules(require('../store/modules/buyer-account/delivery-address.js'), 'modules/buyer-account/delivery-address.js')
  resolveStoreModules(require('../store/modules/buyer-account/offers.js'), 'modules/buyer-account/offers.js')
  resolveStoreModules(require('../store/modules/buyer-account/order-history.js'), 'modules/buyer-account/order-history.js')
  resolveStoreModules(require('../store/modules/buyer-account/tools.js'), 'modules/buyer-account/tools.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
