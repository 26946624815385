import { getQuery } from '~/core/utils/queryAPI'

export default $axios => ({
  async get (sendData) {
    const { data } = await $axios.get(`/cart?${getQuery(sendData)}`, {
      withCredentials: true
    })
    return data
  },

  async add (sendData) {
    const { query, body } = sendData
    const { data } = await $axios.post(`/cart/add?${getQuery(query)}`, body)
    return data
  },

  async changeTotal ({ body, id }) {
    const { data } = await $axios.post(`/cart/${id}`, body)
    return data
  },

  async remove (query) {
    const { data } = await $axios.post(
      `/cart/${query.id}?session_id=${query.session_id}`,
      {
        total: 0
      }
    )
    return data
  },

  async checkout (sendData) {
    const { data } = await $axios.post('/cart/checkout', sendData)
    return data
  }
})
