import { mapState } from 'vuex'
import cloneDeep from 'clone-deep'

export default {
  data () {
    return {
      isMobile: false
    }
  },

  computed: {
    ...mapState({
      language: state => state.localization.language,
      readyFilter: state => state.brand.brand.ready_filter || false
    }),
    isSellerPage () {
      return this.readyFilter && this.routeName === 'seller' && this.isMobile
    }
  },
  methods: {
    translate (key) {
      return this.language[key] || ''
    },
    userTypeFilter (arr) {
      let cloneFilter = cloneDeep(arr)
      if (
        this.$route.query?.userType === 'manufacturer' &&
        this.$route.query?.conditional === 'mounting' &&
        this.$route.params?.metal === 'gold'
      ) {
        const exception = [
          'gemstone',
          'origin',
          'stoneCarat',
          'stoneCertificate',
          'stoneCut'
        ]

        cloneFilter = cloneFilter.filter(e => !exception.includes(e.queryName))

        cloneFilter = cloneFilter.filter((e) => {
          if (e.queryName === 'type') {
            e.options = e.options.filter(felem => felem.value !== 'chain')
          }
          if (e.queryName === 'fineness') {
            e.options = e.options.filter(
              felem => felem.value === 'g750' || felem.value === 'g585'
            )
          }
          return e
        })
      }
      return cloneFilter
    },
    filterMetalType (arr) {
      let cloneFilter = cloneDeep(arr)
      if (
        this.$route.query?.userType === 'manufacturer' &&
        this.$route.query?.conditional === 'mounting'
      ) {
        cloneFilter = cloneFilter.filter((e) => {
          if (e.queryName === 'metal') {
            e.options = e.options.filter(felem => felem.value === 'gold')
          }
          return e
        })
      }
      return cloneFilter
    },
    userTypeFilterSeller (arr) {
      let cloneFilter = cloneDeep(arr)
      if (
        this.$route.query?.userType === 'manufacturer' &&
        this.$route.query?.conditional === 'mounting' &&
        this.$route.query?.metal === 'gold'
      ) {
        const exception = [
          'gemstone',
          'origin',
          'stoneCarat',
          'stoneCertificate',
          'stoneCut'
        ]

        cloneFilter = cloneFilter.filter(e => !exception.includes(e.queryName))

        cloneFilter = cloneFilter.filter((e) => {
          if (e.queryName === 'type') {
            e.options = e.options.filter(felem => felem.value !== 'chain')
          }
          if (e.queryName === 'fineness') {
            e.options = e.options.filter(
              felem => felem.value === 'g750' || felem.value === 'g585'
            )
          }
          if (e.queryName === 'gender') {
            e.options = e.options.filter(
              felem => felem.value === 'women' || felem.value === 'men'
            )
          }
          if (e.queryName === 'metal') {
            e.options = e.options.filter(felem => felem.value === 'gold')
          }
          return e
        })
      }

      return cloneFilter
    },
    userTypeFilterAllSeller (arr) {
      let cloneFilter = cloneDeep(arr)
      if (
        this.$route.query?.userType &&
        this.$route.query?.userType !== 'seller'
      ) {
        cloneFilter = cloneFilter.filter((e) => {
          if (e.queryName === 'category') {
            e.options = e.options.filter(felem => felem.value !== 'souvenir')
          }
          return e
        })
      }
      if (
        this.$route.query?.userType === 'manufacturer' &&
        this.$route.query?.conditional === 'mounting'
      ) {
        cloneFilter = cloneFilter.filter((e) => {
          if (e.queryName === 'category') {
            e.options = e.options.filter(
              felem => felem.value === 'jewelry-gold'
            )
          }
          return e
        })
      }
      return cloneFilter
    },
    handleIsMobile (innerWidth) {
      if (innerWidth < 993) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    }
  },
  mounted () {
    if (process.browser) {
      this.handleIsMobile(window.innerWidth)
      this.resize = window.addEventListener('resize', ({ target }) => {
        // if (this.timeOutResize) {
        //   clearTimeout(this.timeOutResize)
        // }
        // this.timeOutResize = setTimeout(() => {
        this.handleIsMobile(target.innerWidth)
        // }, 0)
      })
    }
  },
  beforeDestroy () {
    if (process.browser) {
      window.removeEventListener('resize', this.resize)
    }
  }
}
