import { collection, collections } from '~/static/mock/lists'

export default {
  state: () => {
    return {
      collections,
      collection
    }
  },
  mutations: {
    setCollections (state, payload) {
      state.collections = payload
    },
    setCollection (state, payload) {
      state.collection = payload
    }
  },
  actions: {
    async list ({ commit }) {
      const { list } = await this.$api.collection.list()
      commit('setCollections', list)
    },
    async get ({ commit }, id) {
      const { list } = await this.$api.collection.get(id)
      commit('setCollection', list)
    }
  },
  namespaced: true
}
