const errorMessage = {
  1: 'Internal Server Error: The server encountered an internal error or misconfiguration and was unable to complete your request.',
  2: 'Authorization error: User credentials are invalid or the user is denied access.',
  3: 'Bad Request: a request failed due to providing bad input.',
  4: 'Parameter is not valid.',
  5: 'Passwords do not match. ',
  6: 'Unable to delete address selected as a default one.',
  7: 'You already have an active price offer.',
  8: 'The product price is determined to be final.',
  9: 'The price offer limit is over.',
  10: 'The cart is empty.',
  11: 'A cart can only contain products from one store.',
  12: 'Invalid phone number.',
  13: 'Main photo cannot be deleted.',
  14: 'Invalid fineness out of (0-999.9] range.',
  15: 'Wait for the admin confirmation, please.',
  16: 'Unable to process payment. Please, contact admin for details.',
  17: 'Authorization error:  token timed out',
  18: '2fa verification code mismatch',
  19: 'Invalid role type',
  20: 'Registration error: Failed to send email',
  21: 'Invalid category id',
  22: 'Not an empty category, to delete a category, it must be empty',
  23: 'The seller does not accept the offer',
  24: 'The store only supports offline trading',
  25: 'The jewelry presented on the website is made in one copy',
  26: 'duplicate product code',
  27: 'product code cannot contain characters other than - _',
  28: 'Invalid category_id',
  29: 'Mismatch of products and from category',
  30: 'The default category cannot be deleted',
  31: 'Invalid password',
  32: 'Unknown error',
  33: 'An order with this number is already registered in the system',
  34: 'Unknown (prohibited) currency',
  35: 'A required query parameter is missing',
  36: 'Request parameter value error',
  37: 'System error',
  38: 'The order was rejected due to an error in the payment details',
  39: 'Unregistered OrderId',
  40: 'Request is missing x-access-token header',
  41: 'Ensure that the email and password included in the request are correct',
  42: 'Invalid token',
  43: 'User without 2FA',
  44: 'Wrong 2FA code',
  45: 'Forbidden request for user',
  46: 'User deactivated',
  47: 'Invalid old password',
  48: 'Incorrect request body json',
  49: 'Authorization error: no token header',
  50: 'Username not allowed on server side',
  51: 'Inadmissible email',
  52: 'Phone number already in use',
  53: 'Passwords do not match',
  54: 'Invalid address id',
  55: 'Invalid product data',
  56: 'Invalid country',
  57: 'Invalid region',
  58: 'Invalid city',
  59: 'Invalid market',
  60: 'Invalid picture list',
  61: 'Get Multipart Form error',
  62: 'No files',
  63: 'Multiple main pictures specified',
  64: 'Internal server error',
  65: 'Database error',
  66: 'Access token error',
  67: 'Invalid captcha code',
  68: 'Invalid sms code',
  69: 'Invalid new pin code format',
  70: 'Invalid old pin'
}

export const ErrorCode = {
  en: {
    'internal-0001': errorMessage['64'],
    'internal-0002': errorMessage['65'],
    'internal-0003': errorMessage['66'],
    'internal-0004': errorMessage['67'],

    'auth-0001': errorMessage['40'],
    'auth-0002': errorMessage['41'],
    'auth-0003': errorMessage['42'],
    'auth-0004': errorMessage['43'],
    'auth-0005': errorMessage['44'],
    'auth-0006': errorMessage['45'],
    'auth-0007': errorMessage['46'],
    'auth-0008': errorMessage['47'],
    'auth-0009': errorMessage['70'],

    'entity-0001': errorMessage['48'],
    'entity-0002': errorMessage['49'],

    'entity-0003': errorMessage['17'],
    'entity-0004': errorMessage['18'],

    'entity-0005': errorMessage['50'],
    'entity-0006': errorMessage['51'],
    'entity-0007': errorMessage['52'],
    'entity-0008': errorMessage['53'],

    'entity-0009': errorMessage['19'],

    'entity-00010': errorMessage['54'],
    'entity-00011': errorMessage['55'],

    'entity-00012': errorMessage['20'],
    'entity-00013': errorMessage['6'],
    'entity-00014': errorMessage['3'],
    'entity-00015': errorMessage['7'],
    'entity-00016': errorMessage['8'],
    'entity-00017': errorMessage['9'],
    'entity-00018': errorMessage['10'],

    'entity-00019': errorMessage['56'],
    'entity-00020': errorMessage['57'],
    'entity-00021': errorMessage['58'],
    'entity-00022': errorMessage['59'],
    'entity-00023': errorMessage['60'],
    'entity-00024': errorMessage['11'],
    'entity-00025': errorMessage['12'],
    'entity-00026': errorMessage['13'],
    'entity-00027': errorMessage['61'],
    'entity-00028': errorMessage['62'],
    'entity-00029': errorMessage['63'],
    'entity-00030': errorMessage['14'],

    'entity-00032': errorMessage['22'],
    'entity-00033': errorMessage['23'],
    'entity-00034': errorMessage['24'],
    'entity-00035': errorMessage['25'],
    'entity-00036': errorMessage['26'],
    'entity-00037': errorMessage['27'],
    'entity-00038': errorMessage['28'],
    'entity-00039': errorMessage['29'],
    'entity-00040': errorMessage['30'],
    'entity-00041': errorMessage['31'],
    'entity-00042': errorMessage['68'],
    'entity-00043': errorMessage['69'],
    'entity-00031': errorMessage['31'],

    'info-0001': errorMessage['15'],

    'ipay-0000': errorMessage['32'],
    'ipay-0001': errorMessage['33'],
    'ipay-0002': errorMessage['34'],
    'ipay-0003': errorMessage['35'],
    'ipay-0004': errorMessage['36'],
    'ipay-0005': errorMessage['37'],
    'ipay-0006': errorMessage['38'],
    'ipay-0007': errorMessage['39']
  },
  ru: {
    'internal-0001': errorMessage['64'],
    'internal-0002': errorMessage['65'],
    'internal-0003': errorMessage['66'],
    'internal-0004': errorMessage['67'],

    'auth-0001': errorMessage['40'],
    'auth-0002': errorMessage['41'],
    'auth-0003': errorMessage['42'],
    'auth-0004': errorMessage['43'],
    'auth-0005': errorMessage['44'],
    'auth-0006': errorMessage['45'],
    'auth-0007': errorMessage['46'],
    'auth-0008': errorMessage['47'],
    'auth-0009': errorMessage['70'],

    'entity-0001': errorMessage['48'],
    'entity-0002': errorMessage['49'],

    'entity-0003': errorMessage['17'],
    'entity-0004': errorMessage['18'],

    'entity-0005': errorMessage['50'],
    'entity-0006': errorMessage['51'],
    'entity-0007': errorMessage['52'],
    'entity-0008': errorMessage['53'],

    'entity-0009': errorMessage['19'],

    'entity-00010': errorMessage['54'],
    'entity-00011': errorMessage['55'],

    'entity-00012': errorMessage['20'],
    'entity-00013': errorMessage['6'],
    'entity-00014': errorMessage['3'],
    'entity-00015': errorMessage['7'],
    'entity-00016': errorMessage['8'],
    'entity-00017': errorMessage['9'],
    'entity-00018': errorMessage['10'],

    'entity-00019': errorMessage['56'],
    'entity-00020': errorMessage['57'],
    'entity-00021': errorMessage['58'],
    'entity-00022': errorMessage['59'],
    'entity-00023': errorMessage['60'],
    'entity-00024': errorMessage['11'],
    'entity-00025': errorMessage['12'],
    'entity-00026': errorMessage['13'],
    'entity-00027': errorMessage['61'],
    'entity-00028': errorMessage['62'],
    'entity-00029': errorMessage['63'],
    'entity-00030': errorMessage['14'],

    'entity-00032': errorMessage['22'],
    'entity-00033': errorMessage['23'],
    'entity-00034': errorMessage['24'],
    'entity-00035': errorMessage['25'],
    'entity-00036': errorMessage['26'],
    'entity-00037': errorMessage['27'],
    'entity-00038': errorMessage['28'],
    'entity-00039': errorMessage['29'],
    'entity-00040': errorMessage['30'],
    'entity-00041': errorMessage['31'],
    'entity-00042': errorMessage['68'],
    'entity-00043': errorMessage['69'],
    'entity-00031': errorMessage['31'],

    'info-0001': errorMessage['15'],

    'ipay-0000': errorMessage['32'],
    'ipay-0001': errorMessage['33'],
    'ipay-0002': errorMessage['34'],
    'ipay-0003': errorMessage['35'],
    'ipay-0004': errorMessage['36'],
    'ipay-0005': errorMessage['37'],
    'ipay-0006': errorMessage['38'],
    'ipay-0007': errorMessage['39']
  },
  am: {
    'internal-0001': errorMessage['64'],
    'internal-0002': errorMessage['65'],
    'internal-0003': errorMessage['66'],
    'internal-0004': errorMessage['67'],

    'auth-0001': errorMessage['40'],
    'auth-0002': errorMessage['41'],
    'auth-0003': errorMessage['42'],
    'auth-0004': errorMessage['43'],
    'auth-0005': errorMessage['44'],
    'auth-0006': errorMessage['45'],
    'auth-0007': errorMessage['46'],
    'auth-0008': errorMessage['47'],
    'auth-0009': errorMessage['70'],

    'entity-0001': errorMessage['48'],
    'entity-0002': errorMessage['49'],

    'entity-0003': errorMessage['17'],
    'entity-0004': errorMessage['18'],

    'entity-0005': errorMessage['50'],
    'entity-0006': errorMessage['51'],
    'entity-0007': errorMessage['52'],
    'entity-0008': errorMessage['53'],

    'entity-0009': errorMessage['19'],

    'entity-00010': errorMessage['54'],
    'entity-00011': errorMessage['55'],

    'entity-00012': errorMessage['20'],
    'entity-00013': errorMessage['6'],
    'entity-00014': errorMessage['3'],
    'entity-00015': errorMessage['7'],
    'entity-00016': errorMessage['8'],
    'entity-00017': errorMessage['9'],
    'entity-00018': errorMessage['10'],

    'entity-00019': errorMessage['56'],
    'entity-00020': errorMessage['57'],
    'entity-00021': errorMessage['58'],
    'entity-00022': errorMessage['59'],
    'entity-00023': errorMessage['60'],
    'entity-00024': errorMessage['11'],
    'entity-00025': errorMessage['12'],
    'entity-00026': errorMessage['13'],
    'entity-00027': errorMessage['61'],
    'entity-00028': errorMessage['62'],
    'entity-00029': errorMessage['63'],
    'entity-00030': errorMessage['14'],

    'entity-00032': errorMessage['22'],
    'entity-00033': errorMessage['23'],
    'entity-00034': errorMessage['24'],
    'entity-00035': errorMessage['25'],
    'entity-00036': errorMessage['26'],
    'entity-00037': errorMessage['27'],
    'entity-00038': errorMessage['28'],
    'entity-00039': errorMessage['29'],
    'entity-00040': errorMessage['30'],
    'entity-00041': errorMessage['31'],
    'entity-00042': errorMessage['68'],
    'entity-00043': errorMessage['69'],
    'entity-00031': errorMessage['31'],

    'info-0001': errorMessage['15'],

    'ipay-0000': errorMessage['32'],
    'ipay-0001': errorMessage['33'],
    'ipay-0002': errorMessage['34'],
    'ipay-0003': errorMessage['35'],
    'ipay-0004': errorMessage['36'],
    'ipay-0005': errorMessage['37'],
    'ipay-0006': errorMessage['38'],
    'ipay-0007': errorMessage['39']
  }
}
