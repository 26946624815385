export default $axios => ({
  async get (id) {
    const { data } = await $axios.get(`/user/info/${id}`)
    return data
  },

  async put (sendData) {
    const { data } = await $axios.put('/user/update_user_info', sendData)
    return data
  },

  async aboutStore (id) {
    const { data } = await $axios.get(`/user/about_store/${id}`)
    return data
  }
})
