export default ({ app }) => {
  // Assuming you have a variable that holds the current version
  const currentVersion = 'v1'

  // Check if the version-specific cookie prefix matches the current version
  const versionPrefix = app.$cookies.get('versionPrefix')
  if (versionPrefix !== currentVersion) {
    // Clear all cookies if the version prefix doesn't match
    app.$cookies.removeAll()
    // Set the version-specific cookie prefix for the current version
    app.$cookies.set('versionPrefix', currentVersion)
  }
}
