import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _35f68d2b = () => interopDefault(import('../pages/about-gold-center/index.vue' /* webpackChunkName: "pages/about-gold-center/index" */))
const _df8a413e = () => interopDefault(import('../pages/aja/index.vue' /* webpackChunkName: "pages/aja/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _368b5adc = () => interopDefault(import('../pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _2161b8d6 = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _01d9bacc = () => interopDefault(import('../pages/auth/check-email.vue' /* webpackChunkName: "pages/auth/check-email" */))
const _7666dd5c = () => interopDefault(import('../pages/auth/create-new-password.vue' /* webpackChunkName: "pages/auth/create-new-password" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _23d2ff46 = () => interopDefault(import('../pages/auth/registration.vue' /* webpackChunkName: "pages/auth/registration" */))
const _7deff9aa = () => interopDefault(import('../pages/auth/registration/buyer.vue' /* webpackChunkName: "pages/auth/registration/buyer" */))
const _4df3af98 = () => interopDefault(import('../pages/auth/registration/seller.vue' /* webpackChunkName: "pages/auth/registration/seller" */))
const _94619c28 = () => interopDefault(import('../pages/auth/registration/service.vue' /* webpackChunkName: "pages/auth/registration/service" */))
const _0033a319 = () => interopDefault(import('../pages/buyer-account.vue' /* webpackChunkName: "pages/buyer-account" */))
const _b8149a48 = () => interopDefault(import('../pages/buyer-account/index.vue' /* webpackChunkName: "pages/buyer-account/index" */))
const _45312881 = () => interopDefault(import('../pages/buyer-account/delivery-address.vue' /* webpackChunkName: "pages/buyer-account/delivery-address" */))
const _88f28306 = () => interopDefault(import('../pages/buyer-account/offers.vue' /* webpackChunkName: "pages/buyer-account/offers" */))
const _440152e2 = () => interopDefault(import('../pages/buyer-account/order-history/index.vue' /* webpackChunkName: "pages/buyer-account/order-history/index" */))
const _3c49e789 = () => interopDefault(import('../pages/buyer-account/settings.vue' /* webpackChunkName: "pages/buyer-account/settings" */))
const _8750b136 = () => interopDefault(import('../pages/buyer-account/tools.vue' /* webpackChunkName: "pages/buyer-account/tools" */))
const _742aa5ca = () => interopDefault(import('../pages/buyer-account/order-history/_id.vue' /* webpackChunkName: "pages/buyer-account/order-history/_id" */))
const _07b969e6 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _42aaf102 = () => interopDefault(import('../pages/FAQ/index.vue' /* webpackChunkName: "pages/FAQ/index" */))
const _ae1f78ca = () => interopDefault(import('../pages/fix.vue' /* webpackChunkName: "pages/fix" */))
const _dcb72c44 = () => interopDefault(import('../pages/fix/index.vue' /* webpackChunkName: "pages/fix/index" */))
const _18964818 = () => interopDefault(import('../pages/guaranteed-checking/index.vue' /* webpackChunkName: "pages/guaranteed-checking/index" */))
const _30234a40 = () => interopDefault(import('../pages/jobs.vue' /* webpackChunkName: "pages/jobs" */))
const _7de68bc4 = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _8e968990 = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _27edb935 = () => interopDefault(import('../pages/ratesws.vue' /* webpackChunkName: "pages/ratesws" */))
const _3d268e12 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _716eefa8 = () => interopDefault(import('../pages/seller/index.vue' /* webpackChunkName: "pages/seller/index" */))
const _e9039c6a = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _2902f4c3 = () => interopDefault(import('../pages/shopping-centers.vue' /* webpackChunkName: "pages/shopping-centers" */))
const _ce3b0988 = () => interopDefault(import('../pages/souvenir/index.vue' /* webpackChunkName: "pages/souvenir/index" */))
const _00807e9e = () => interopDefault(import('../pages/terms-of-conditions/index.vue' /* webpackChunkName: "pages/terms-of-conditions/index" */))
const _28e626b3 = () => interopDefault(import('../pages/thanks.vue' /* webpackChunkName: "pages/thanks" */))
const _2fec9e86 = () => interopDefault(import('../pages/watches/index.vue' /* webpackChunkName: "pages/watches/index" */))
const _983549a2 = () => interopDefault(import('../pages/wishlist.vue' /* webpackChunkName: "pages/wishlist" */))
const _1082b40c = () => interopDefault(import('../pages/aja/registration.vue' /* webpackChunkName: "pages/aja/registration" */))
const _54fe454a = () => interopDefault(import('../pages/cart/checkout.vue' /* webpackChunkName: "pages/cart/checkout" */))
const _9bc9ace0 = () => interopDefault(import('../pages/forms/dlr.vue' /* webpackChunkName: "pages/forms/dlr" */))
const _2972a594 = () => interopDefault(import('../pages/seller/_id.vue' /* webpackChunkName: "pages/seller/_id" */))
const _000cd60b = () => interopDefault(import('../pages/shopping-center/_id.vue' /* webpackChunkName: "pages/shopping-center/_id" */))
const _2220fbde = () => interopDefault(import('../pages/souvenir/_metal/index.vue' /* webpackChunkName: "pages/souvenir/_metal/index" */))
const _08def787 = () => interopDefault(import('../pages/watches/_metal/index.vue' /* webpackChunkName: "pages/watches/_metal/index" */))
const _c639bf24 = () => interopDefault(import('../pages/product/_category/_id.vue' /* webpackChunkName: "pages/product/_category/_id" */))
const _bcb92116 = () => interopDefault(import('../pages/_metal/index.vue' /* webpackChunkName: "pages/_metal/index" */))
const _5122bcbf = () => interopDefault(import('../pages/_metal/_type/index.vue' /* webpackChunkName: "pages/_metal/_type/index" */))
const _b18cf4b6 = () => interopDefault(import('../pages/_metal/_type/_subcategory/index.vue' /* webpackChunkName: "pages/_metal/_type/_subcategory/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-gold-center",
    component: _35f68d2b,
    name: "about-gold-center___am___default"
  }, {
    path: "/aja",
    component: _df8a413e,
    name: "aja___am___default"
  }, {
    path: "/am",
    component: _2dfb1658,
    name: "index___am"
  }, {
    path: "/auth",
    component: _368b5adc,
    children: [{
      path: "",
      component: _2161b8d6,
      name: "auth___am___default"
    }, {
      path: "check-email",
      component: _01d9bacc,
      name: "auth-check-email___am___default"
    }, {
      path: "create-new-password",
      component: _7666dd5c,
      name: "auth-create-new-password___am___default"
    }, {
      path: "login",
      component: _8e1272a8,
      name: "auth-login___am___default"
    }, {
      path: "registration",
      component: _23d2ff46,
      name: "auth-registration___am___default",
      children: [{
        path: "buyer",
        component: _7deff9aa,
        name: "auth-registration-buyer___am___default"
      }, {
        path: "seller",
        component: _4df3af98,
        name: "auth-registration-seller___am___default"
      }, {
        path: "service",
        component: _94619c28,
        name: "auth-registration-service___am___default"
      }]
    }]
  }, {
    path: "/buyer-account",
    component: _0033a319,
    children: [{
      path: "",
      component: _b8149a48,
      name: "buyer-account___am___default"
    }, {
      path: "delivery-address",
      component: _45312881,
      name: "buyer-account-delivery-address___am___default"
    }, {
      path: "offers",
      component: _88f28306,
      name: "buyer-account-offers___am___default"
    }, {
      path: "order-history",
      component: _440152e2,
      name: "buyer-account-order-history___am___default"
    }, {
      path: "settings",
      component: _3c49e789,
      name: "buyer-account-settings___am___default"
    }, {
      path: "tools",
      component: _8750b136,
      name: "buyer-account-tools___am___default"
    }, {
      path: "order-history/:id?",
      component: _742aa5ca,
      name: "buyer-account-order-history-id___am___default"
    }]
  }, {
    path: "/cart",
    component: _07b969e6,
    name: "cart___am___default"
  }, {
    path: "/en",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/FAQ",
    component: _42aaf102,
    name: "FAQ___am___default"
  }, {
    path: "/fix",
    component: _ae1f78ca,
    children: [{
      path: "",
      component: _dcb72c44,
      name: "fix___am___default"
    }]
  }, {
    path: "/guaranteed-checking",
    component: _18964818,
    name: "guaranteed-checking___am___default"
  }, {
    path: "/jobs",
    component: _30234a40,
    name: "jobs___am___default"
  }, {
    path: "/privacy-policy",
    component: _7de68bc4,
    name: "privacy-policy___am___default"
  }, {
    path: "/product",
    component: _8e968990,
    name: "product___am___default"
  }, {
    path: "/ratesws",
    component: _27edb935,
    name: "ratesws___am___default"
  }, {
    path: "/ru",
    component: _2dfb1658,
    name: "index___ru"
  }, {
    path: "/search",
    component: _3d268e12,
    name: "search___am___default"
  }, {
    path: "/seller",
    component: _716eefa8,
    name: "seller___am___default"
  }, {
    path: "/services",
    component: _e9039c6a,
    name: "services___am___default"
  }, {
    path: "/shopping-centers",
    component: _2902f4c3,
    name: "shopping-centers___am___default"
  }, {
    path: "/souvenir",
    component: _ce3b0988,
    name: "souvenir___am___default"
  }, {
    path: "/terms-of-conditions",
    component: _00807e9e,
    name: "terms-of-conditions___am___default"
  }, {
    path: "/thanks",
    component: _28e626b3,
    name: "thanks___am___default"
  }, {
    path: "/watches",
    component: _2fec9e86,
    name: "watches___am___default"
  }, {
    path: "/wishlist",
    component: _983549a2,
    name: "wishlist___am___default"
  }, {
    path: "/aja/registration",
    component: _1082b40c,
    name: "aja-registration___am___default"
  }, {
    path: "/am/about-gold-center",
    component: _35f68d2b,
    name: "about-gold-center___am"
  }, {
    path: "/am/aja",
    component: _df8a413e,
    name: "aja___am"
  }, {
    path: "/am/auth",
    component: _368b5adc,
    children: [{
      path: "",
      component: _2161b8d6,
      name: "auth___am"
    }, {
      path: "check-email",
      component: _01d9bacc,
      name: "auth-check-email___am"
    }, {
      path: "create-new-password",
      component: _7666dd5c,
      name: "auth-create-new-password___am"
    }, {
      path: "login",
      component: _8e1272a8,
      name: "auth-login___am"
    }, {
      path: "registration",
      component: _23d2ff46,
      name: "auth-registration___am",
      children: [{
        path: "buyer",
        component: _7deff9aa,
        name: "auth-registration-buyer___am"
      }, {
        path: "seller",
        component: _4df3af98,
        name: "auth-registration-seller___am"
      }, {
        path: "service",
        component: _94619c28,
        name: "auth-registration-service___am"
      }]
    }]
  }, {
    path: "/am/buyer-account",
    component: _0033a319,
    children: [{
      path: "",
      component: _b8149a48,
      name: "buyer-account___am"
    }, {
      path: "delivery-address",
      component: _45312881,
      name: "buyer-account-delivery-address___am"
    }, {
      path: "offers",
      component: _88f28306,
      name: "buyer-account-offers___am"
    }, {
      path: "order-history",
      component: _440152e2,
      name: "buyer-account-order-history___am"
    }, {
      path: "settings",
      component: _3c49e789,
      name: "buyer-account-settings___am"
    }, {
      path: "tools",
      component: _8750b136,
      name: "buyer-account-tools___am"
    }, {
      path: "order-history/:id?",
      component: _742aa5ca,
      name: "buyer-account-order-history-id___am"
    }]
  }, {
    path: "/am/cart",
    component: _07b969e6,
    name: "cart___am"
  }, {
    path: "/am/FAQ",
    component: _42aaf102,
    name: "FAQ___am"
  }, {
    path: "/am/fix",
    component: _ae1f78ca,
    children: [{
      path: "",
      component: _dcb72c44,
      name: "fix___am"
    }]
  }, {
    path: "/am/guaranteed-checking",
    component: _18964818,
    name: "guaranteed-checking___am"
  }, {
    path: "/am/jobs",
    component: _30234a40,
    name: "jobs___am"
  }, {
    path: "/am/privacy-policy",
    component: _7de68bc4,
    name: "privacy-policy___am"
  }, {
    path: "/am/product",
    component: _8e968990,
    name: "product___am"
  }, {
    path: "/am/ratesws",
    component: _27edb935,
    name: "ratesws___am"
  }, {
    path: "/am/search",
    component: _3d268e12,
    name: "search___am"
  }, {
    path: "/am/seller",
    component: _716eefa8,
    name: "seller___am"
  }, {
    path: "/am/services",
    component: _e9039c6a,
    name: "services___am"
  }, {
    path: "/am/shopping-centers",
    component: _2902f4c3,
    name: "shopping-centers___am"
  }, {
    path: "/am/souvenir",
    component: _ce3b0988,
    name: "souvenir___am"
  }, {
    path: "/am/terms-of-conditions",
    component: _00807e9e,
    name: "terms-of-conditions___am"
  }, {
    path: "/am/thanks",
    component: _28e626b3,
    name: "thanks___am"
  }, {
    path: "/am/watches",
    component: _2fec9e86,
    name: "watches___am"
  }, {
    path: "/am/wishlist",
    component: _983549a2,
    name: "wishlist___am"
  }, {
    path: "/cart/checkout",
    component: _54fe454a,
    name: "cart-checkout___am___default"
  }, {
    path: "/en/about-gold-center",
    component: _35f68d2b,
    name: "about-gold-center___en"
  }, {
    path: "/en/aja",
    component: _df8a413e,
    name: "aja___en"
  }, {
    path: "/en/auth",
    component: _368b5adc,
    children: [{
      path: "",
      component: _2161b8d6,
      name: "auth___en"
    }, {
      path: "check-email",
      component: _01d9bacc,
      name: "auth-check-email___en"
    }, {
      path: "create-new-password",
      component: _7666dd5c,
      name: "auth-create-new-password___en"
    }, {
      path: "login",
      component: _8e1272a8,
      name: "auth-login___en"
    }, {
      path: "registration",
      component: _23d2ff46,
      name: "auth-registration___en",
      children: [{
        path: "buyer",
        component: _7deff9aa,
        name: "auth-registration-buyer___en"
      }, {
        path: "seller",
        component: _4df3af98,
        name: "auth-registration-seller___en"
      }, {
        path: "service",
        component: _94619c28,
        name: "auth-registration-service___en"
      }]
    }]
  }, {
    path: "/en/buyer-account",
    component: _0033a319,
    children: [{
      path: "",
      component: _b8149a48,
      name: "buyer-account___en"
    }, {
      path: "delivery-address",
      component: _45312881,
      name: "buyer-account-delivery-address___en"
    }, {
      path: "offers",
      component: _88f28306,
      name: "buyer-account-offers___en"
    }, {
      path: "order-history",
      component: _440152e2,
      name: "buyer-account-order-history___en"
    }, {
      path: "settings",
      component: _3c49e789,
      name: "buyer-account-settings___en"
    }, {
      path: "tools",
      component: _8750b136,
      name: "buyer-account-tools___en"
    }, {
      path: "order-history/:id?",
      component: _742aa5ca,
      name: "buyer-account-order-history-id___en"
    }]
  }, {
    path: "/en/cart",
    component: _07b969e6,
    name: "cart___en"
  }, {
    path: "/en/FAQ",
    component: _42aaf102,
    name: "FAQ___en"
  }, {
    path: "/en/fix",
    component: _ae1f78ca,
    children: [{
      path: "",
      component: _dcb72c44,
      name: "fix___en"
    }]
  }, {
    path: "/en/guaranteed-checking",
    component: _18964818,
    name: "guaranteed-checking___en"
  }, {
    path: "/en/jobs",
    component: _30234a40,
    name: "jobs___en"
  }, {
    path: "/en/privacy-policy",
    component: _7de68bc4,
    name: "privacy-policy___en"
  }, {
    path: "/en/product",
    component: _8e968990,
    name: "product___en"
  }, {
    path: "/en/ratesws",
    component: _27edb935,
    name: "ratesws___en"
  }, {
    path: "/en/search",
    component: _3d268e12,
    name: "search___en"
  }, {
    path: "/en/seller",
    component: _716eefa8,
    name: "seller___en"
  }, {
    path: "/en/services",
    component: _e9039c6a,
    name: "services___en"
  }, {
    path: "/en/shopping-centers",
    component: _2902f4c3,
    name: "shopping-centers___en"
  }, {
    path: "/en/souvenir",
    component: _ce3b0988,
    name: "souvenir___en"
  }, {
    path: "/en/terms-of-conditions",
    component: _00807e9e,
    name: "terms-of-conditions___en"
  }, {
    path: "/en/thanks",
    component: _28e626b3,
    name: "thanks___en"
  }, {
    path: "/en/watches",
    component: _2fec9e86,
    name: "watches___en"
  }, {
    path: "/en/wishlist",
    component: _983549a2,
    name: "wishlist___en"
  }, {
    path: "/forms/dlr",
    component: _9bc9ace0,
    name: "forms-dlr___am___default"
  }, {
    path: "/ru/about-gold-center",
    component: _35f68d2b,
    name: "about-gold-center___ru"
  }, {
    path: "/ru/aja",
    component: _df8a413e,
    name: "aja___ru"
  }, {
    path: "/ru/auth",
    component: _368b5adc,
    children: [{
      path: "",
      component: _2161b8d6,
      name: "auth___ru"
    }, {
      path: "check-email",
      component: _01d9bacc,
      name: "auth-check-email___ru"
    }, {
      path: "create-new-password",
      component: _7666dd5c,
      name: "auth-create-new-password___ru"
    }, {
      path: "login",
      component: _8e1272a8,
      name: "auth-login___ru"
    }, {
      path: "registration",
      component: _23d2ff46,
      name: "auth-registration___ru",
      children: [{
        path: "buyer",
        component: _7deff9aa,
        name: "auth-registration-buyer___ru"
      }, {
        path: "seller",
        component: _4df3af98,
        name: "auth-registration-seller___ru"
      }, {
        path: "service",
        component: _94619c28,
        name: "auth-registration-service___ru"
      }]
    }]
  }, {
    path: "/ru/buyer-account",
    component: _0033a319,
    children: [{
      path: "",
      component: _b8149a48,
      name: "buyer-account___ru"
    }, {
      path: "delivery-address",
      component: _45312881,
      name: "buyer-account-delivery-address___ru"
    }, {
      path: "offers",
      component: _88f28306,
      name: "buyer-account-offers___ru"
    }, {
      path: "order-history",
      component: _440152e2,
      name: "buyer-account-order-history___ru"
    }, {
      path: "settings",
      component: _3c49e789,
      name: "buyer-account-settings___ru"
    }, {
      path: "tools",
      component: _8750b136,
      name: "buyer-account-tools___ru"
    }, {
      path: "order-history/:id?",
      component: _742aa5ca,
      name: "buyer-account-order-history-id___ru"
    }]
  }, {
    path: "/ru/cart",
    component: _07b969e6,
    name: "cart___ru"
  }, {
    path: "/ru/FAQ",
    component: _42aaf102,
    name: "FAQ___ru"
  }, {
    path: "/ru/fix",
    component: _ae1f78ca,
    children: [{
      path: "",
      component: _dcb72c44,
      name: "fix___ru"
    }]
  }, {
    path: "/ru/guaranteed-checking",
    component: _18964818,
    name: "guaranteed-checking___ru"
  }, {
    path: "/ru/jobs",
    component: _30234a40,
    name: "jobs___ru"
  }, {
    path: "/ru/privacy-policy",
    component: _7de68bc4,
    name: "privacy-policy___ru"
  }, {
    path: "/ru/product",
    component: _8e968990,
    name: "product___ru"
  }, {
    path: "/ru/ratesws",
    component: _27edb935,
    name: "ratesws___ru"
  }, {
    path: "/ru/search",
    component: _3d268e12,
    name: "search___ru"
  }, {
    path: "/ru/seller",
    component: _716eefa8,
    name: "seller___ru"
  }, {
    path: "/ru/services",
    component: _e9039c6a,
    name: "services___ru"
  }, {
    path: "/ru/shopping-centers",
    component: _2902f4c3,
    name: "shopping-centers___ru"
  }, {
    path: "/ru/souvenir",
    component: _ce3b0988,
    name: "souvenir___ru"
  }, {
    path: "/ru/terms-of-conditions",
    component: _00807e9e,
    name: "terms-of-conditions___ru"
  }, {
    path: "/ru/thanks",
    component: _28e626b3,
    name: "thanks___ru"
  }, {
    path: "/ru/watches",
    component: _2fec9e86,
    name: "watches___ru"
  }, {
    path: "/ru/wishlist",
    component: _983549a2,
    name: "wishlist___ru"
  }, {
    path: "/am/aja/registration",
    component: _1082b40c,
    name: "aja-registration___am"
  }, {
    path: "/am/cart/checkout",
    component: _54fe454a,
    name: "cart-checkout___am"
  }, {
    path: "/am/forms/dlr",
    component: _9bc9ace0,
    name: "forms-dlr___am"
  }, {
    path: "/en/aja/registration",
    component: _1082b40c,
    name: "aja-registration___en"
  }, {
    path: "/en/cart/checkout",
    component: _54fe454a,
    name: "cart-checkout___en"
  }, {
    path: "/en/forms/dlr",
    component: _9bc9ace0,
    name: "forms-dlr___en"
  }, {
    path: "/ru/aja/registration",
    component: _1082b40c,
    name: "aja-registration___ru"
  }, {
    path: "/ru/cart/checkout",
    component: _54fe454a,
    name: "cart-checkout___ru"
  }, {
    path: "/ru/forms/dlr",
    component: _9bc9ace0,
    name: "forms-dlr___ru"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___am___default"
  }, {
    path: "/am/seller/:id",
    component: _2972a594,
    name: "seller-id___am"
  }, {
    path: "/am/shopping-center/:id?",
    component: _000cd60b,
    name: "shopping-center-id___am"
  }, {
    path: "/am/souvenir/:metal",
    component: _2220fbde,
    name: "souvenir-metal___am"
  }, {
    path: "/am/watches/:metal",
    component: _08def787,
    name: "watches-metal___am"
  }, {
    path: "/en/seller/:id",
    component: _2972a594,
    name: "seller-id___en"
  }, {
    path: "/en/shopping-center/:id?",
    component: _000cd60b,
    name: "shopping-center-id___en"
  }, {
    path: "/en/souvenir/:metal",
    component: _2220fbde,
    name: "souvenir-metal___en"
  }, {
    path: "/en/watches/:metal",
    component: _08def787,
    name: "watches-metal___en"
  }, {
    path: "/ru/seller/:id",
    component: _2972a594,
    name: "seller-id___ru"
  }, {
    path: "/ru/shopping-center/:id?",
    component: _000cd60b,
    name: "shopping-center-id___ru"
  }, {
    path: "/ru/souvenir/:metal",
    component: _2220fbde,
    name: "souvenir-metal___ru"
  }, {
    path: "/ru/watches/:metal",
    component: _08def787,
    name: "watches-metal___ru"
  }, {
    path: "/am/product/:category/:id?",
    component: _c639bf24,
    name: "product-category-id___am"
  }, {
    path: "/en/product/:category/:id?",
    component: _c639bf24,
    name: "product-category-id___en"
  }, {
    path: "/ru/product/:category/:id?",
    component: _c639bf24,
    name: "product-category-id___ru"
  }, {
    path: "/am/:metal",
    component: _bcb92116,
    name: "metal___am"
  }, {
    path: "/en/:metal",
    component: _bcb92116,
    name: "metal___en"
  }, {
    path: "/ru/:metal",
    component: _bcb92116,
    name: "metal___ru"
  }, {
    path: "/seller/:id",
    component: _2972a594,
    name: "seller-id___am___default"
  }, {
    path: "/shopping-center/:id?",
    component: _000cd60b,
    name: "shopping-center-id___am___default"
  }, {
    path: "/souvenir/:metal",
    component: _2220fbde,
    name: "souvenir-metal___am___default"
  }, {
    path: "/watches/:metal",
    component: _08def787,
    name: "watches-metal___am___default"
  }, {
    path: "/am/:metal/:type",
    component: _5122bcbf,
    name: "metal-type___am"
  }, {
    path: "/en/:metal/:type",
    component: _5122bcbf,
    name: "metal-type___en"
  }, {
    path: "/product/:category/:id?",
    component: _c639bf24,
    name: "product-category-id___am___default"
  }, {
    path: "/ru/:metal/:type",
    component: _5122bcbf,
    name: "metal-type___ru"
  }, {
    path: "/am/:metal/:type/:subcategory",
    component: _b18cf4b6,
    name: "metal-type-subcategory___am"
  }, {
    path: "/en/:metal/:type/:subcategory",
    component: _b18cf4b6,
    name: "metal-type-subcategory___en"
  }, {
    path: "/ru/:metal/:type/:subcategory",
    component: _b18cf4b6,
    name: "metal-type-subcategory___ru"
  }, {
    path: "/:metal",
    component: _bcb92116,
    name: "metal___am___default"
  }, {
    path: "/:metal/:type",
    component: _5122bcbf,
    name: "metal-type___am___default"
  }, {
    path: "/:metal/:type/:subcategory",
    component: _b18cf4b6,
    name: "metal-type-subcategory___am___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
