<template>
  <span v-if="Object.keys(currenciesInfo || {}).length" class="price">
    {{ noFormat ? renderPrice : renderFormatPrice(renderPrice) }}
  </span>
</template>

<script>
import { mapState } from 'vuex'
import { currencys } from '~/core/utils/currencys'
import { formatPrice as renderFormatPrice } from '~/core/utils'

export default {
  name: 'AppPrice',
  props: {
    price: {
      type: [Number, String],
      required: true
    },
    hiddenSimbol: {
      type: Boolean,
      required: false,
      default: () => false
    },
    noFloor: {
      type: Boolean,
      default: () => false
    },
    noFormat: {
      type: Boolean,
      default: () => false
    },
    currencyProps: {
      required: false,
      type: String,
      default: () => ''
    },
    defaultCurrency: {
      required: false,
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      currentPrice: ''
    }
  },
  computed: {
    ...mapState({
      currenciesInfo: state => state.buyerAccount.dashboard.currencies || {},
      handleChangeCurency: state => state.ui.currency || ''
    }),
    renderPrice () {
      const [price, simbol] = this.currentPrice.split(' ')
      if (this.noFloor) {
        return price
      }
      const onesDigit = price % 10
      if (simbol === '֏') {
        if (this.defaultCurrency) {
          return this.price
        }
        if (onesDigit >= 5) {
          return `${Math.floor(price / 10) * 10 + 10}${simbol}`
        } else {
          return `${Math.floor(price / 10) * 10}${simbol}`
        }
      } else {
        const mutationPrice = +price
        // eslint-disable-next-line no-unused-vars
        const [_, sum] = mutationPrice.toFixed(1).split('.')
        if (sum < 6) {
          return `${mutationPrice.toFixed(0)}${simbol}`
        }
        return `${mutationPrice.toFixed(1)}${simbol}`
      }
    },
    currency () {
      if (this.$isServer) {
        return 'amd'
      }
      return this.$cookies.get('currency')
    }
  },
  watch: {
    handleChangeCurency: {
      immediate: true,
      handler () {
        this.formatPrice()
      }
    },
    price: {
      immediate: false,
      handler () {
        this.formatPrice()
      }
    },
    currencyProps: {
      immediate: true,
      handler () {
        this.formatPrice()
      }
    },
    currenciesInfo: {
      immediate: true,
      handler () {
        this.formatPrice()
      }
    }
  },
  methods: {
    renderFormatPrice,
    getPrice () {
      const currencies = {
        amd: 'price',
        usd: 'price_usd',
        rub: 'price_ru',
        eur: 'price_eur'
      }
      return currencies[this.currency]
    },
    formatPrice () {
      const currency = this.currencyProps || this.$cookies.get('currency') || ''
      const felem = currencys.find(e => e.code === currency)
      if (this.defaultCurrency && this.currencyProps === 'amd') {
        this.currentPrice = `${(this.price * this.currenciesInfo.usd).toFixed(
          2
        )} ${!this.hiddenSimbol ? felem?.simbol : ''}`
        return
      }
      if (this.defaultCurrency && this.currenciesInfo[currency]) {
        this.currentPrice = `${(
          (this.price * this.currenciesInfo.usd) /
          this.currenciesInfo[currency]
        ).toFixed(2)} ${!this.hiddenSimbol ? felem?.simbol : ''}`
        return
      }
      if (this.currenciesInfo[currency]) {
        this.currentPrice = `${(
          this.price / this.currenciesInfo[currency]
        ).toFixed(2)} ${!this.hiddenSimbol ? felem?.simbol : ''}`
        return
      }
      this.currentPrice = `${this.price} ${
        !this.hiddenSimbol ? felem?.simbol : ''
      }`
    }
  }
}
</script>

<style scoped>
.price{
  word-spacing: -3px;
}
</style>
