import { subcategory } from '~/core/utils/header-navigation'

export default function ({ redirect, localePath, route }) {
  const { params, query } = route
  const isValidSubCategory = subcategory.includes(params.subcategory)
  if (
    query.userType === 'manufacturer' &&
    query?.conditional === 'mounting'
  ) {
    redirect(localePath('/'))
  }
  if (!isValidSubCategory) {
    redirect(localePath('/'))
  }
}
