export default async function (context) {
  const { redirect, $cookies, store, route, i18n } = context
  const logout = route.query?.logout || ''
  const lang = route.query?.lang || ''
  const token = await $cookies.get('accessToken')
  /* START TODO need delete this redirects in 6 month */
  // if (route.path === '/collection' || route.path === '/collections') {
  //   redirect('/')
  // }
  // if (route.path === '/ru/collection' || route.path === '/ru/collections') {
  //   redirect('/ru')
  // }
  // if (route.path === '/en/collection' || route.path === '/en/collections') {
  //   redirect('/en')
  // }
  if (route.path.search('/jewelry') > 0) {
    const _route = route.path.replace('/jewelry', '')
    redirect(_route || '/')
  }
  /* END */
  if (logout === token) {
    await store.dispatch('auth/logout')
    redirect('/')
  }
  if (lang) {
    i18n.setLocale(lang)
    redirect('/')
  }
}
