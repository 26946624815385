<template>
  <div class="error-404">
    <div v-if="error.statusCode === 404" class="error-404">
      <h1 class="error-text-title">
        404
      </h1>
      <h1 class="error-text">
        PAGE NOT FOUND
      </h1>
    </div>

    <div v-else class="error-404">
      <h1 class="error-text-title ">
        {{ error.statusCode }}
      </h1>
      <h1 class="error-text">
        AN ERROR OCCURRED
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'error',
  props: ['error'] // you can set a custom layout for the error page
}
</script>

<style>
.error-404 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}
.error-text-title {
  font-weight: bold;
  color: #8c511e;
  font-size: 50px;
}
.error-text {
  font-weight: bold;
  color: #8c511e;
}
.redirect-link {
  position: absolute;
  z-index: 2;
  top: 25px;
  left: 25px;
  width: 130px;
  padding: 5px 10px;
  background: #fff;
  border-radius: 8px;
}
</style>
