export default {
  state: () => {
    return {
      catalogListInfo: {},
      catalog: {},
      seller: {}
    }
  },
  mutations: {
    setCatalogListInfo (state, payload) {
      state.catalogListInfo = payload
    },

    setCatalog (state, payload) {
      state.catalog = payload
    },

    setSeller (state, payload) {
      state.seller = payload
    }
  },
  actions: {
    async list ({ commit, state }, sendData) {
      // eslint-disable-next-line camelcase
      const { total_count, catalog } = await this.$api.catalog.list(sendData)
      if (sendData.loadMore) {
        commit('setCatalogListInfo', {
          catalog: [...state.catalogListInfo.catalog, ...catalog],
          totalCount: total_count
        })
      } else {
        commit('setCatalogListInfo', {
          catalog,
          totalCount: total_count
        })
      }
    },

    async get ({ commit }, id) {
      const { catalog } = await this.$api.catalog.get(id)
      commit('setCatalog', catalog)
    },

    async getSeller ({ commit }, id) {
      const { catalog } = await this.$api.catalog.getSeller(id)
      commit('setSeller', catalog)
    }
  },
  namespaced: true
}
