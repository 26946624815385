import { getQuery } from '~/core/utils/queryAPI'

export default $axios => ({
  async search ({ query }) {
    const { data } = await $axios.get(`/search?${getQuery(query)}`)
    return data
  },

  async get (id) {
    const { data } = await $axios.get(`/product/page/${id}`)
    return data
  },

  async like (id) {
    const { data } = await $axios.post('/product', { id })
    return data
  }
})
