export default async function ({ store, redirect, localePath, $cookies }) {
  try {
    const sendData = {
      limit: 100,
      offset: 0,
      session_id: await $cookies.get('session_id')
    }
    await store.dispatch('auth/getCurrentUser')
    await store.dispatch('cart/get', sendData)

    const currentUser = store.state.auth.currentUser
    const cartTotalCount = store.state.cart.cartListInfo.total_count
    const cartListInfo = store.state.cart.cartListInfo
    const isBuyer = currentUser.role_id === 4

    if (!currentUser) {
      redirect(localePath('/auth/login'))
      return
    }
    if (currentUser && cartListInfo && !cartTotalCount) {
      redirect('/')
      return
    }
    if (currentUser && !isBuyer) {
      const lang = await $cookies.get('lang')

      redirect(
        `https://admin.goldcenter.am?token=${$cookies.get(
          'accessToken'
        )}&lang=${lang}`
      )
    }
  } catch {}
}
