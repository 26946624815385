export default {
  state: () => {
    return {
      currentUser: null
    }
  },

  mutations: {
    login (_, token) {
      const favoriteData = {
        query: {
          limit: 100,
          offset: 0
        },
        loadMore: false
      }
      const cartData = {
        limit: 100,
        offset: 0,
        session_id: this.$cookies.get('session_id')
      }
      this.dispatch('cart/get', cartData)
      this.dispatch('favorite/list', favoriteData)
      this.$cookies.set('accessToken', token)
    },

    logout (state) {
      state.currentUser = null
      this.commit('favorite/setListInfo', {})
      this.commit('cart/setCartListInfo', {})
      this.$cookies.remove('accessToken')
      this.$cookies.remove('session_id')
      // this.$router.push({ path: this.localePath('/') })
    },

    setCurrentUser (state, payload) {
      state.currentUser = { ...payload }
    }
  },

  actions: {
    async login ({ commit }, sendData) {
      try {
        const data = await this.$api.auth.login(sendData)
        commit('login', data.token)
        return data
      } catch (e) {
        console.log(e)
      }
    },

    async logout ({ commit }) {
      try {
        await commit('logout')
        const [name] = this.$router.currentRoute.name.split('___')
        let match = false
        const exceptionList = [
          'buyer-account-delivery-address',
          'buyer-account-order-history',
          'buyer-account-settings',
          'buyer-account-offers',
          'cart-checkout',
          'wishlist'
        ]
        exceptionList.forEach((e) => {
          if (name.includes(e)) {
            match = true
          }
        })
        if (match) {
          this.$router.push({ path: this.localePath('/') })
        }
      } catch (e) {
        console.log(e)
      }
    },

    async registration (_, registerData) {
      try {
        return await this.$api.auth.register(registerData)
      } catch (e) {
        console.log(e)
      }
    },

    verify (_, code) {
      try {
        return this.$api.auth.verify(code)
      } catch (e) {
        console.log(e)
      }
    },

    async restore (_, sendData) {
      try {
        return await this.$api.auth.restore(sendData)
      } catch (e) {
        console.log(e)
      }
    },

    async confirmRestore (_, sendData) {
      try {
        return await this.$api.auth.confirmRestore(sendData)
      } catch (e) {
        console.log(e)
      }
    },

    async updatePassword (_, sendData) {
      try {
        return await this.$api.auth.updatePassword(sendData)
      } catch (e) {
        console.log(e)
      }
    },

    async getCurrentUser ({ commit }) {
      try {
        commit('setCurrentUser', await this.$api.currentUser.get())
      } catch (e) {
        console.log(e)
      }
    }
  },
  namespaced: true
}
