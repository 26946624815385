export default $axios => ({
  async list () {
    const { data } = await $axios.get('/mock/collections.json')
    return data
  },
  async get () {
    const { data } = await $axios.get('/mock/collection.json')
    return data
  }
})
