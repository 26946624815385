<template>
  <div class="global-layout-container" :style="`--heightFooter: ${footerHeight}px`" v-bind:class="!switchRoute?'gold':'silver'">
    <client-only>
      <app-basket-right />
    </client-only>
    <app-header class="menu __nav" />
    <modal-check-email />
    <client-only>
      <div class="header">
        <modal-auth />
        <modal-forgot-password />
        <modal-alert />
      </div>
      <app-cascader-mobile v-if="isMobile" :filters="globalFilters || []" />
    </client-only>
    <main id="global-layout" class="global-layout-content height-100" :class="{'is-jobs': !isJobs}">
      <nuxt />
    </main>
    <app-footer />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Message from 'element-ui/lib/message'

export default {
  components: {
    AppHeader: () => import('~/components/section/app-header.vue'),
    AppFooter: () => import('~/components/section/app-footer.vue'),
    AppCascaderMobile: () => import('~/components/ui/app-cascader-mobile.vue'),
    AppBasketRight: () => import('~/components/ui/app-basket-right.vue'),
    ModalAuth: () => import('~/components/modals/modal-auth.vue'),
    ModalForgotPassword: () => import('~/components/modals/modal-forgot-password.vue'),
    ModalAlert: () => import('~/components/modals/modal-alert.vue'),
    ModalCheckEmail: () => import('~/components/modals/modal-check-email.vue')
  },
  middleware: 'default',
  data () {
    return {
      sitemode: this.$store.state.siteMode.sitemode,
      scrollPosition: null,
      footerHeight: 0
    }
  },
  head () {
    const alternates = []
    this.$i18n.locales.forEach((locale) => {
      let href = process.env.VUE_APP_URL
      if (locale.code !== 'am') {
        href += `/${locale.code}`
      }
      alternates.push({
        hid: `alternate-${locale.code}`,
        rel: 'alternate',
        hreflang: locale.code,
        href
      })
    })
    return {
      meta: [
        {
          name: 'content',
          content: this.$route.path.slice(1).replace(/\//gi, '-') || ''
        },
        {
          name: 'og:url',
          content: `${process.env.VUE_APP_URL}${this.$route.path}`
        }
      ],
      link: [
        {
          rel: 'alternate',
          href: `${process.env.VUE_APP_URL}/sitemap.xml`,
          type: 'application/rss+xml'
        },
        ...alternates
      ]
    }
  },

  computed: {
    ...mapState({
      alert: state => state.ui.alert,
      globalFilters: state => state.ui.globalFilters,
      isMobMenu: state => state.ui.isMobMenu,
      isModal: state => state.ui.isModal,
      isJobs: state => state.ui.isJobs
    }),
    switchRoute () {
      return this.$store.state.siteMode.sitemode === 'silver'
    },
    isOverflow () {
      return this.isModal || this.isMobMenu
    },
    currentRoute () {
      return this.$route
    },
    routeHistory () {
      const routeHistory = localStorage.getItem('routeHistory')
      return routeHistory ? JSON.parse(routeHistory) : []
    },
    currentRouteIndex () {
      return this.routeHistory.indexOf(this.$route.fullPath)
    },
    totalRoutes () {
      return this.routeHistory.length
    }
  },

  watch: {
    alert ({ message, type }) {
      if (message) {
        Message({ message, type })
      }
    },
    isOverflow (val) {
      if (process.browser) {
        document.body.style.overflow = val ? 'hidden' : ''
      }
    },
    $route (to, from) {
      this.$store.commit('product/setSearchInfo', {})
    }
  },
  async mounted () {
    // window.addEventListener('scroll', this.handleScroll)
    if (process.browser) {
      localStorage.setItem('routeHistory', JSON.stringify([this.$route.path]))
      await this.cba()
      const sessionsId = this.$cookies.get('session_id')
      const accessToken = this.$cookies.get('accessToken')
      if (accessToken) {
        await this.getCurrentUser()
      }
      if (sessionsId || this.currentUser) {
        await this.getCartsRequest()
      }

      if (this.currentUser) {
        this.getfavorite()
      }
      this.handleWindowResize()
      // this.updateCBA = setInterval(() => {
      //   this.cba()
      // }, 30000)

      // Google Analitics

      window.dataLayer = window.dataLayer || []
      function gtag () {
        // eslint-disable-next-line no-undef
        dataLayer.push(arguments)
      }
      gtag('js', new Date())
      gtag('config', 'G-3C1XFXBXKC')
    }
  },
  methods: {
    ...mapActions({
      getCurrentUser: 'auth/getCurrentUser',
      getCarts: 'cart/get',
      cba: 'buyerAccount/dashboard/cba',
      getfavoriteList: 'favorite/list'
    }),
    async getCartsRequest () {
      const sendData = {
        limit: 100,
        offset: 0,
        session_id: await this.$cookies.get('session_id')
      }
      this.getCarts(sendData)
    },
    handleWindowResize () {
      // Need for 'correct' height off scroll
      if (process.browser) {
        this.$nextTick(async () => {
          const footer = await document?.getElementById('main-footer')
          this.footerHeight = footer?.offsetHeight
        })
      }
    },
    getfavorite () {
      const body = {
        query: {
          limit: 1000,
          offset: 0
        },
        loadMore: false
      }

      this.getfavoriteList(body)
    }
  }
}
</script>

<style>
.global-layout-content{
  margin-bottom: auto;
}
.is-jobs{
  padding-top: 36px;
}
.__nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: top 0.3s ease-in-out;
}

.switch.switcher-wrapper {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch.switcher-wrapper input {
  display: none;
}

.switcher-wrapper .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switcher-wrapper .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switcher-wrapper input:checked + .slider {
  background-color: #101010;
}

.switcher-wrapper input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

.switcher-wrapper input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.switcher-wrapper .slider.round {
  border-radius: 34px;
}

.switcher-wrapper .slider.round:before {
  border-radius: 50%;
}
</style>
