export default {
  state: () => {
    return {
      offerInfo: {}
    }
  },
  mutations: {
    setOfferInfo (state, payload) {
      state.offerInfo = payload
    }
  },
  actions: {
    async get ({ commit }) {
      const data = await this.$api.buyerAccount.offer.get()
      commit('setOfferInfo', data)
    }
  },
  namespaced: true
}
