import dashboardApi from './dashboard'
import offerApi from './offers'
import deliveryAddressApi from './delivery-address'
import orderHistoryApi from './order-history'
import toolsApi from './tools'

export default ($axios) => {
  return {
    dashboard: dashboardApi($axios),
    offer: offerApi($axios),
    deliveryAddress: deliveryAddressApi($axios),
    orderHistory: orderHistoryApi($axios),
    tools: toolsApi($axios)
  }
}
