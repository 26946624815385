export default {
  data () {
    return {
      availableCities: ['yerevan', 'kotayk', 'armavir']
    }
  },
  computed: {
    routeName () {
      const [name] = this.$route.name.split('___')
      return name
    },
    keyRouterName () {
      const random = (Math.random() + 1).toString(36).substring(7)
      return Object.values(this.$route.params).join('.') + '_' + random
    }
  },
  methods: {
    navigate ({ path, query }) {
      this.$router.push({
        query: { ...(query || null) }
      })
    },
    changeBrandRoute (id) {
      if (this.routeName === 'seller' && this.$route.query.category) {
        const [category, metal] = this.$route.query.category.split('-')
        let query = {}
        if (category === 'jewelry') {
          query = {
            ...this.$route.query,
            category,
            metal
          }
        } else {
          query = {
            ...this.$route.query
          }
        }
        this.$router.push({ path: this.localePath(`/seller/${id}`), query })
      } else {
        this.$router.push({
          path: this.localePath(`/seller/${id}`)
        })
      }
    }
  }
}
