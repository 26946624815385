import { getQuery } from '../core/utils/queryAPI'

export default $axios => ({
  async list (body) {
    const { query } = body
    const { data } = await $axios.get(`/products/catalog/souvenir?${getQuery(query)}`)
    return data
  },

  async getForWhom (body) {
    const { query, url } = body
    const { data } = await $axios.get(`/products/catalog/souvenir/${url}?${getQuery(query)}`)
    return data
  }
})
