import { CATEGORYS, GENDERS, METALS } from './enums'

export const purityTypes = [
  {
    title: '999 - 24K',
    value: 'g999',
    id: 1,
    access: [CATEGORYS.SOUVENIR],
    accessMetal: [METALS.GOLD],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '995 - 24K',
    value: 'g995',
    id: 2,
    access: [],
    accessMetal: [],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '958 - 23K',
    value: 'g958',
    id: 3,
    access: [CATEGORYS.JEWELRY],
    accessMetal: [METALS.GOLD],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '916 - 22K',
    value: 'g916',
    id: 4,
    access: [CATEGORYS.JEWELRY],
    accessMetal: [METALS.GOLD],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '900 - 21.6K',
    value: 'g900',
    id: 5,
    access: [CATEGORYS.JEWELRY],
    accessMetal: [],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '875 - 21K',
    value: 'g875',
    id: 6,
    access: [CATEGORYS.JEWELRY],
    accessMetal: [METALS.GOLD],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '750 - 18K',
    value: 'g750',
    id: 7,
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    accessMetal: [METALS.GOLD, METALS.OTHER],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '585 - 14K',
    value: 'g585',
    id: 8,
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    accessMetal: [METALS.GOLD, METALS.OTHER],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '500 - 12K',
    value: 'g500',
    id: 9,
    access: [CATEGORYS.JEWELRY],
    accessMetal: [],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '416 - 10K',
    value: 'g416',
    id: 10,
    access: [CATEGORYS.JEWELRY],
    accessMetal: [],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '375 - 9K',
    value: 'g375',
    id: 11,
    access: [CATEGORYS.JEWELRY],
    accessMetal: [],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '333 - 8K',
    value: 'g333',
    id: 12,
    access: [CATEGORYS.JEWELRY],
    accessMetal: [],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '999',
    value: 's999',
    id: 13,
    access: [CATEGORYS.JEWELRY],
    accessMetal: [],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '995',
    value: 's995',
    id: 14,
    access: [CATEGORYS.JEWELRY],
    accessMetal: [],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  },
  {
    title: '925',
    value: 's925',
    id: 15,
    access: [CATEGORYS.JEWELRY, CATEGORYS.WATCHES],
    accessMetal: [METALS.SILVER, METALS.OTHER],
    accessGender: [GENDERS.MEN, GENDERS.WOMEN, GENDERS.BOY, GENDERS.GIRL]
  }
]
export const months = [
  // {
  //   title: 'basic.month',
  //   value: '',
  //   maxDay: 31
  // },
  {
    value: '0',
    title: 'month.january',
    maxDay: 31
  },
  {
    value: '01',
    title: 'month.february',
    maxDay: 28
  },
  {
    value: '02',
    title: 'month.march',
    maxDay: 31
  },
  {
    value: '03',
    title: 'month.april',
    maxDay: 30
  },
  {
    value: '04',
    title: 'month.may',
    maxDay: 31
  },
  {
    value: '05',
    title: 'month.june',
    maxDay: 30
  },
  {
    value: '06',
    title: 'month.july',
    maxDay: 31
  },
  {
    value: '07',
    title: 'month.august',
    maxDay: 31
  },
  {
    value: '08',
    title: 'month.september',
    maxDay: 30
  },
  {
    value: '9',
    title: 'month.october',
    maxDay: 31
  },
  {
    value: '10',
    title: 'month.november',
    maxDay: 30
  },
  {
    value: '11',
    title: 'month.december',
    maxDay: 30
  }
]
export const years = [
  // {
  //   title: 'basic.year',
  //   value: ''
  // },
  {
    title: '2024',
    value: '2024'
  },
  {
    title: '2023',
    value: '2023'
  },
  {
    title: '2022',
    value: '2022'
  },
  {
    title: '2021',
    value: '2021'
  }
]

export const ranges = [
  {
    title: 'basic.day',
    value: 'day'
  },
  {
    title: 'basic.week',
    value: 'week'
  },
  {
    title: 'basic.month',
    value: 'month'
  },
  {
    title: 'basic.year',
    value: 'year'
  }
]
