export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"am","iso":"am-AM","name":"ՀԱՅ","file":"am-AM.js","icon":"arm.png"},{"code":"en","iso":"en-US","name":"ENG","file":"en-US.js","icon":"en.png"},{"code":"ru","iso":"ru-RU","name":"РУС","file":"ru-RU.js","icon":"ru.png"}],
  defaultLocale: "am",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_and_default",
  lazy: true,
  langDir: "/app/lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":".goldcenter.am/","cookieKey":"lang","cookieSecure":false,"fallbackLocale":"am","onlyOnNoPrefix":false,"onlyOnRoot":true,"useCookie":true,"redirectOn":"root"},
  differentDomains: false,
  seo: true,
  baseUrl: "https://goldcenter.am/",
  vuex: {"moduleName":"i18n","syncLocale":true,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  rootDomain: ".goldcenter.am/",
  normalizedLocales: [{"code":"am","iso":"am-AM","name":"ՀԱՅ","file":"am-AM.js","icon":"arm.png"},{"code":"en","iso":"en-US","name":"ENG","file":"en-US.js","icon":"en.png"},{"code":"ru","iso":"ru-RU","name":"РУС","file":"ru-RU.js","icon":"ru.png"}],
  localeCodes: ["am","en","ru"],
}

export const localeMessages = {
  'am-AM.js': () => import('../../lang/am-AM.js' /* webpackChunkName: "lang-am-AM.js" */),
  'en-US.js': () => import('../../lang/en-US.js' /* webpackChunkName: "lang-en-US.js" */),
  'ru-RU.js': () => import('../../lang/ru-RU.js' /* webpackChunkName: "lang-ru-RU.js" */),
}
