// import siteMode from '~/store/modules/site-mode'

export default {
  state: () => {
    return {
      mainInfo: {}
    }
  },
  mutations: {
    setMainInfo (state, payload) {
      state.mainInfo = payload
    }
  },
  actions: {
    async list ({ commit }, query) {
      const data = await this.$api.main.list(query) //, [siteMode.siteMode])
      commit('setMainInfo', data)
    }
  },
  namespaced: true
}
