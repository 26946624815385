export default $axios => ({
  async post (body) {
    const { data } = await $axios.post('/offer/new', body)
    return data
  },
  async put (id) {
    const { data } = await $axios.put('/offer/new', id)
    return data
  },
  async delete (id) {
    const { data } = await $axios.put(`/offer/reject/${id}`)
    return data
  },
  async sellerStatus (id) {
    const { data } = await $axios.get(`/offer/seller/${id}`)
    return data
  }
})
