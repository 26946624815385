export default async function ({ store, redirect, localePath }) {
  try {
    await store.dispatch('auth/getCurrentUser')
    const currentUser = store.state.auth.currentUser
    if (!currentUser) {
      redirect(localePath('/auth/login'))
      return
    }
  } catch {
    redirect(localePath('/auth/login'))
  }
}
