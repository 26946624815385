import { getQuery } from '~/core/utils/queryAPI'

export default $axios => ({
  async list (body) {
    const { query } = body
    const { data } = await $axios.get(`/favorite?${getQuery(query)}`)
    return data
  },
  async put (id) {
    const { data } = await $axios.put(`/favorite/${id}`)
    return data
  }
})
