export default $axios => ({
  async get () {
    const { data } = await $axios.get('/delivery')
    return data
  },
  async post (body) {
    const { data } = await $axios.post('/delivery/add_address', body)
    return data
  },
  async put (body) {
    const { data } = await $axios.put('/delivery/update_address', body)
    return data
  },
  async makeDefaultAddress (body) {
    const { data } = await $axios.put('/delivery/make_default_address', body)
    return data
  },

  async delete (id) {
    const { data } = await $axios.put('/delivery/delete_address', { id })
    return data
  }
})
