import { USER_ROLES } from '~/core/globals/roles'

export default function ({ store, redirect, route }) {
  const [meta] = route.meta
  const userRole = USER_ROLES.ADMIN
  const role = meta.role.includes(userRole)
  if (!role) {
    redirect('/role-no-access')
  }
}
