export default {
  state: () => {
    return {
      categories: {}
    }
  },
  mutations: {
    setCategories (state, payload) {
      state.categories = payload
    }
  },
  actions: {
    async list ({ commit, state }) {
      // eslint-disable-next-line camelcase
      const { categories } = await this.$api.categories.list()
      commit('setCategories', categories)
    }
  },
  namespaced: true
}
