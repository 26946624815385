export default {
  state: () => {
    return {
      listInfo: {}
    }
  },
  mutations: {
    setlistInfo (state, payload) {
      state.listInfo = payload
    }
  },
  actions: {
    async list ({ commit, state }, query) {
      const { loadMore } = query
      const data = await this.$api.souvenir.list(query)
      const mutationData = loadMore
        ? { ...data, catalog: [...state.listInfo.catalog, ...data.catalog] }
        : data
      commit('setlistInfo', mutationData)
    },

    async getListForWhom ({ commit, state }, query) {
      const { loadMore } = query
      const data = await this.$api.souvenir.getForWhom(query)
      const mutationData = loadMore
        ? { ...data, catalog: [...state.listInfo.catalog, ...data.catalog] }
        : data
      commit('setlistInfo', mutationData)
    }
  },
  namespaced: true
}
