import { getQuery } from '../core/utils/queryAPI'

export default $axios => ({
  async list () {
    const { data } = await $axios.get('/mock/shopping-centers.json')
    return data
  },
  async get (id) {
    const { data } = await $axios.get(
      `/mock/shopping-center.json?${getQuery(id)}`
    )
    return data
  }
})
