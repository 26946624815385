export default {
  state: () => {
    return {
      addressList: [],
      address: {}
    }
  },
  mutations: {
    setAddressList (state, payload) {
      state.addressList = payload
    },
    setAddress (state, payload) {
      state.address = payload
    }
  },
  actions: {
    async list ({ commit }) {
      const { addresses } = await this.$api.buyerAccount.deliveryAddress.get()
      commit('setAddressList', addresses)
      return addresses
    },
    async post (_, body) {
      return await this.$api.buyerAccount.deliveryAddress.post(body)
    },
    async put (_, body) {
      return await this.$api.buyerAccount.deliveryAddress.put(body)
    },
    async makeDefaultAddress (_, body) {
      return await this.$api.buyerAccount.deliveryAddress.makeDefaultAddress(body)
    },
    async delete (_, id) {
      return await this.$api.buyerAccount.deliveryAddress.delete(id)
    }
  },
  namespaced: true
}
