export default $axios => ({
  async getUserGroupInfo (userId) {
    const { data } = await $axios.get(`/preorder/prices?user_id=${userId}`)
    return data
  },

  async submitPreOrder (preOrderData) {
    const { data } = await $axios.post('/pre-order', preOrderData)
    return data
  }

  // Add other API endpoints related to pre-orders as needed
})
