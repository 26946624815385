export default $axios => ({
  async getAbout () {
    const { data } = await $axios.get('/aja/about_us')
    return data
  },

  async getMembers () {
    const { data } = await $axios.get('/aja/members')
    return data
  },

  async putInfo (info) {
    const { data } = await $axios.put('/aja/info', info)
    return data
  },

  async register (shop) {
    const { data } = await $axios.post('/aja/register', shop)
    return data
  }
})
