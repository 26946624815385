export default {
  state: () => {
    return {
      productInfo: null,
      searchInfo: {}
    }
  },
  mutations: {
    setProductInfo (state, payload) {
      state.productInfo = payload
    },
    setSearchInfo (state, payload) {
      state.searchInfo = payload
    }
  },
  actions: {
    // async search ({ commit }, query) {
    //   const data = await this.$api.product.search(query)
    //   commit('setSearchInfo', data)
    // },

    async search ({ commit, state }, query) {
      const { loadMore } = query
      const data = await this.$api.product.search(query)
      const mutationData = loadMore
        ? { ...data, catalog: [...state.searchInfo.catalog, ...data.catalog] }
        : data
      commit('setSearchInfo', mutationData)
    },

    async get ({ commit }, id) {
      const data = await this.$api.product.get(id)
      commit('setProductInfo', data)
    },

    async like (_, id) {
      await this.$api.product.like(id)
    }
  },
  namespaced: true
}
